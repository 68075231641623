export const Images = {
    arabic1_hd: require('./Assets/Images/Arabic/Arabic_HD-1.jpg'),
    arabic2_hd: require('./Assets/Images/Arabic/Arabic_HD-2.png'),
    arabic3_hd: require('./Assets/Images/Arabic/Arabic_HD-3.png'),
    arabic4_hd: require('./Assets/Images/Arabic/Arabic_HD-4.jpg'),
    arabic5_hd: require('./Assets/Images/Arabic/Arabic_HD-5.jpg'),
    arabic6_hd: require('./Assets/Images/Arabic/Arabic_HD-6.png'),
    arabic7_hd: require('./Assets/Images/Arabic/Arabic_HD-7.png'),
    arabic8_hd: require('./Assets/Images/Arabic/Arabic_HD-8.png'),
    arabic9_hd: require('./Assets/Images/Arabic/Arabic_HD-9.png'),
    arabic10_hd: require('./Assets/Images/Arabic/Arabic_HD-10.pdf'),
    arabic11_hd: require('./Assets/Images/Arabic/Arabic_HD-11.pdf'),
    arabic12_hd: require('./Assets/Images/Arabic/Arabic_HD-12.jpg'),
    arabic13_hd: require('./Assets/Images/Arabic/Arabic_HD-13.pdf'),
    arabic14_hd: require('./Assets/Images/Arabic/Arabic_HD-14.pdf'),
    arabic15_hd: require('./Assets/Images/Arabic/Arabic_HD-15.pdf'),
    arabic16_hd: require('./Assets/Images/Arabic/Arabic_HD-16.pdf'),
    arabic17_hd: require('./Assets/Images/Arabic/Arabic_HD-17.png'),
    arabic18_hd: require('./Assets/Images/Arabic/Arabic_HD-18.pdf'),
    arabic19_hd: require('./Assets/Images/Arabic/Arabic_HD-19.pdf'),
    arabic20_hd: require('./Assets/Images/Arabic/Arabic_HD-20.pdf'),
    arabic21_hd: require('./Assets/Images/Arabic/Arabic_HD-21.pdf'),
    arabic22_hd: require('./Assets/Images/Arabic/Arabic_HD-22.pdf'),
    arabic23_hd: require('./Assets/Images/Arabic/Arabic_HD-23.pdf'),
    arabic24_hd: require('./Assets/Images/Arabic/Arabic_HD-24.pdf'),
    arabic25_hd: require('./Assets/Images/Arabic/Arabic_HD-25.pdf'),
    arabic26_hd: require('./Assets/Images/Arabic/Arabic_HD-26.pdf'),
    arabic27_hd: require('./Assets/Images/Arabic/Arabic_HD-27.pdf'),
    arabic28_hd: require('./Assets/Images/Arabic/Arabic_HD-28.pdf'),
    arabic29_hd: require('./Assets/Images/Arabic/Arabic_HD-29.pdf'),
    arabic30_hd: require('./Assets/Images/Arabic/Arabic_HD-30.pdf'),
    arabic31_hd: require('./Assets/Images/Arabic/Arabic_HD-31.pdf'),
    arabic32_hd: require('./Assets/Images/Arabic/Arabic_HD-32.pdf'),
    arabic33_hd: require('./Assets/Images/Arabic/Arabic_HD-33.pdf'),
    arabic34_hd: require('./Assets/Images/Arabic/Arabic_HD-34.pdf'),
    arabic35_hd: require('./Assets/Images/Arabic/Arabic_HD-35.pdf'),
    arabic36_hd: require('./Assets/Images/Arabic/Arabic_HD-36.pdf'),
    arabic37_hd: require('./Assets/Images/Arabic/Arabic_HD-37.pdf'),
    arabic38_hd: require('./Assets/Images/Arabic/Arabic_HD-38.pdf'),
    arabic39_hd: require('./Assets/Images/Arabic/Arabic_HD-39.pdf'),
    arabic40_hd: require('./Assets/Images/Arabic/Arabic_HD-40.pdf'),
    arabic41_hd: require('./Assets/Images/Arabic/Arabic_HD-41.pdf'),
    arabic42_hd: require('./Assets/Images/Arabic/Arabic_HD-42.pdf'),
    arabic43_hd: require('./Assets/Images/Arabic/Arabic_HD-43.pdf'),
    arabic44_hd: require('./Assets/Images/Arabic/Arabic_HD-44.pdf'),
    arabic45_hd: require('./Assets/Images/Arabic/Arabic_HD-45.pdf'),
    arabic46_hd: require('./Assets/Images/Arabic/Arabic_HD-46.pdf'),
    arabic47_hd: require('./Assets/Images/Arabic/Arabic_HD-47.pdf'),
    arabic48_hd: require('./Assets/Images/Arabic/Arabic_HD-48.pdf'),

    arabic1_ld: require('./Assets/Images/Arabic/Arabic_LD-1.jpg'),
    arabic2_ld: require('./Assets/Images/Arabic/Arabic_LD-2.png'),
    arabic3_ld: require('./Assets/Images/Arabic/Arabic_LD-3.png'),
    arabic4_ld: require('./Assets/Images/Arabic/Arabic_LD-4.jpg'),
    arabic5_ld: require('./Assets/Images/Arabic/Arabic_LD-5.jpg'),
    arabic6_ld: require('./Assets/Images/Arabic/Arabic_LD-6.png'),
    arabic7_ld: require('./Assets/Images/Arabic/Arabic_LD-7.png'),
    arabic8_ld: require('./Assets/Images/Arabic/Arabic_LD-8.png'),
    arabic9_ld: require('./Assets/Images/Arabic/Arabic_LD-9.png'),
    arabic10_ld: require('./Assets/Images/Arabic/Arabic_LD-10.png'),
    arabic11_ld: require('./Assets/Images/Arabic/Arabic_LD-11.png'),
    arabic12_ld: require('./Assets/Images/Arabic/Arabic_LD-12.jpg'),
    arabic13_ld: require('./Assets/Images/Arabic/Arabic_LD-13.png'),
    arabic14_ld: require('./Assets/Images/Arabic/Arabic_LD-14.png'),
    arabic15_ld: require('./Assets/Images/Arabic/Arabic_LD-15.png'),
    arabic16_ld: require('./Assets/Images/Arabic/Arabic_LD-16.png'),
    arabic17_ld: require('./Assets/Images/Arabic/Arabic_LD-17.png'),
    arabic18_ld: require('./Assets/Images/Arabic/Arabic_LD-18.png'),
    arabic19_ld: require('./Assets/Images/Arabic/Arabic_LD-19.png'),
    arabic20_ld: require('./Assets/Images/Arabic/Arabic_LD-20.png'),
    arabic21_ld: require('./Assets/Images/Arabic/Arabic_LD-21.png'),
    arabic22_ld: require('./Assets/Images/Arabic/Arabic_LD-22.png'),
    arabic23_ld: require('./Assets/Images/Arabic/Arabic_LD-23.png'),
    arabic24_ld: require('./Assets/Images/Arabic/Arabic_LD-24.png'),
    arabic25_ld: require('./Assets/Images/Arabic/Arabic_LD-25.png'),
    arabic26_ld: require('./Assets/Images/Arabic/Arabic_LD-26.png'),
    arabic27_ld: require('./Assets/Images/Arabic/Arabic_LD-27.png'),
    arabic28_ld: require('./Assets/Images/Arabic/Arabic_LD-28.png'),
    arabic29_ld: require('./Assets/Images/Arabic/Arabic_LD-29.png'),
    arabic30_ld: require('./Assets/Images/Arabic/Arabic_LD-30.png'),
    arabic31_ld: require('./Assets/Images/Arabic/Arabic_LD-31.png'),
    arabic32_ld: require('./Assets/Images/Arabic/Arabic_LD-32.jpg'),
    arabic33_ld: require('./Assets/Images/Arabic/Arabic_LD-33.jpg'),
    arabic34_ld: require('./Assets/Images/Arabic/Arabic_LD-34.jpg'),
    arabic35_ld: require('./Assets/Images/Arabic/Arabic_LD-35.jpg'),
    arabic36_ld: require('./Assets/Images/Arabic/Arabic_LD-36.jpg'),
    arabic37_ld: require('./Assets/Images/Arabic/Arabic_LD-37.jpg'),
    arabic38_ld: require('./Assets/Images/Arabic/Arabic_LD-38.jpg'),
    arabic39_ld: require('./Assets/Images/Arabic/Arabic_LD-39.jpg'),
    arabic40_ld: require('./Assets/Images/Arabic/Arabic_LD-40.jpg'),
    arabic41_ld: require('./Assets/Images/Arabic/Arabic_LD-41.jpg'),
    arabic42_ld: require('./Assets/Images/Arabic/Arabic_LD-42.jpg'),
    arabic43_ld: require('./Assets/Images/Arabic/Arabic_LD-43.jpg'),
    arabic44_ld: require('./Assets/Images/Arabic/Arabic_LD-44.jpg'),
    arabic45_ld: require('./Assets/Images/Arabic/Arabic_LD-45.jpg'),
    arabic46_ld: require('./Assets/Images/Arabic/Arabic_LD-46.jpg'),
    arabic47_ld: require('./Assets/Images/Arabic/Arabic_LD-47.jpg'),
    arabic48_ld: require('./Assets/Images/Arabic/Arabic_LD-48.jpg'),

    bangali1_hd: require('./Assets/Images/Bangali/Bangali_HD-1.jpg'),
    bangali2_hd: require('./Assets/Images/Bangali/Bangali_HD-2.jpg'),
    bangali3_hd: require('./Assets/Images/Bangali/Bangali_HD-3.jpg'),
    bangali4_hd: require('./Assets/Images/Bangali/Bangali_HD-4.jpg'),
    bangali5_hd: require('./Assets/Images/Bangali/Bangali_HD-5.jpg'),
    bangali6_hd: require('./Assets/Images/Bangali/Bangali_HD-6.jpg'),
    bangali7_hd: require('./Assets/Images/Bangali/Bangali_HD-7.png'),
    bangali8_hd: require('./Assets/Images/Bangali/Bangali_HD-8.png'),
    bangali9_hd: require('./Assets/Images/Bangali/Bangali_HD-9.png'),
    bangali10_hd: require('./Assets/Images/Bangali/Bangali_HD-10.pdf'),
    bangali11_hd: require('./Assets/Images/Bangali/Bangali_HD-11.pdf'),
    bangali12_hd: require('./Assets/Images/Bangali/Bangali_HD-12.jpg'),
    bangali13_hd: require('./Assets/Images/Bangali/Bangali_HD-13.pdf'),
    bangali14_hd: require('./Assets/Images/Bangali/Bangali_HD-14.pdf'),
    bangali15_hd: require('./Assets/Images/Bangali/Bangali_HD-15.pdf'),
    bangali16_hd: require('./Assets/Images/Bangali/Bangali_HD-16.pdf'),
    bangali17_hd: require('./Assets/Images/Bangali/Bangali_HD-17.pdf'),
    bangali18_hd: require('./Assets/Images/Bangali/Bangali_HD-18.pdf'),
    bangali19_hd: require('./Assets/Images/Bangali/Bangali_HD-19.pdf'),
    bangali20_hd: require('./Assets/Images/Bangali/Bangali_HD-20.pdf'),
    bangali21_hd: require('./Assets/Images/Bangali/Bangali_HD-21.pdf'),
    bangali22_hd: require('./Assets/Images/Bangali/Bangali_HD-22.pdf'),
    bangali23_hd: require('./Assets/Images/Bangali/Bangali_HD-23.pdf'),
    bangali24_hd: require('./Assets/Images/Bangali/Bangali_HD-24.pdf'),
    bangali25_hd: require('./Assets/Images/Bangali/Bangali_HD-25.pdf'),
    bangali26_hd: require('./Assets/Images/Bangali/Bangali_HD-26.pdf'),
    bangali27_hd: require('./Assets/Images/Bangali/Bangali_HD-27.pdf'),
    bangali28_hd: require('./Assets/Images/Bangali/Bangali_HD-28.pdf'),
    bangali29_hd: require('./Assets/Images/Bangali/Bangali_HD-29.pdf'),
    bangali30_hd: require('./Assets/Images/Bangali/Bangali_HD-30.pdf'),
    bangali31_hd: require('./Assets/Images/Bangali/Bangali_HD-31.pdf'),
    bangali32_hd: require('./Assets/Images/Bangali/Bangali_HD-32.pdf'),
    bangali33_hd: require('./Assets/Images/Bangali/Bangali_HD-33.pdf'),
    bangali34_hd: require('./Assets/Images/Bangali/Bangali_HD-34.pdf'),
    bangali35_hd: require('./Assets/Images/Bangali/Bangali_HD-35.pdf'),
    bangali36_hd: require('./Assets/Images/Bangali/Bangali_HD-36.pdf'),
    bangali37_hd: require('./Assets/Images/Bangali/Bangali_HD-37.pdf'),
    bangali38_hd: require('./Assets/Images/Bangali/Bangali_HD-38.pdf'),
    bangali39_hd: require('./Assets/Images/Bangali/Bangali_HD-39.pdf'),
    bangali40_hd: require('./Assets/Images/Bangali/Bangali_HD-40.pdf'),
    bangali41_hd: require('./Assets/Images/Bangali/Bangali_HD-41.pdf'),
    bangali42_hd: require('./Assets/Images/Bangali/Bangali_HD-42.pdf'),
    bangali43_hd: require('./Assets/Images/Bangali/Bangali_HD-43.pdf'),
    bangali44_hd: require('./Assets/Images/Bangali/Bangali_HD-44.pdf'),
    bangali45_hd: require('./Assets/Images/Bangali/Bangali_HD-45.pdf'),
    bangali46_hd: require('./Assets/Images/Bangali/Bangali_HD-46.pdf'),
    bangali47_hd: require('./Assets/Images/Bangali/Bangali_HD-47.pdf'),
    bangali48_hd: require('./Assets/Images/Bangali/Bangali_HD-48.pdf'),
    bangali49_hd: require('./Assets/Images/Bangali/Bangali_HD-49.pdf'),

    bangali1_ld: require('./Assets/Images/Bangali/Bangali_LD-1.jpg'),
    bangali2_ld: require('./Assets/Images/Bangali/Bangali_LD-2.jpg'),
    bangali3_ld: require('./Assets/Images/Bangali/Bangali_LD-3.jpg'),
    bangali4_ld: require('./Assets/Images/Bangali/Bangali_LD-4.jpg'),
    bangali5_ld: require('./Assets/Images/Bangali/Bangali_LD-5.jpg'),
    bangali6_ld: require('./Assets/Images/Bangali/Bangali_LD-6.jpg'),
    bangali7_ld: require('./Assets/Images/Bangali/Bangali_LD-7.png'),
    bangali8_ld: require('./Assets/Images/Bangali/Bangali_LD-8.png'),
    bangali9_ld: require('./Assets/Images/Bangali/Bangali_LD-9.png'),
    bangali10_ld: require('./Assets/Images/Bangali/Bangali_LD-10.png'),
    bangali11_ld: require('./Assets/Images/Bangali/Bangali_LD-11.png'),
    bangali12_ld: require('./Assets/Images/Bangali/Bangali_LD-12.jpg'),
    bangali13_ld: require('./Assets/Images/Bangali/Bangali_LD-13.png'),
    bangali14_ld: require('./Assets/Images/Bangali/Bangali_LD-14.png'),
    bangali15_ld: require('./Assets/Images/Bangali/Bangali_LD-15.png'),
    bangali16_ld: require('./Assets/Images/Bangali/Bangali_LD-16.png'),
    bangali17_ld: require('./Assets/Images/Bangali/Bangali_LD-17.png'),
    bangali18_ld: require('./Assets/Images/Bangali/Bangali_LD-18.png'),
    bangali19_ld: require('./Assets/Images/Bangali/Bangali_LD-19.png'),
    bangali20_ld: require('./Assets/Images/Bangali/Bangali_LD-20.png'),
    bangali21_ld: require('./Assets/Images/Bangali/Bangali_LD-21.png'),
    bangali22_ld: require('./Assets/Images/Bangali/Bangali_LD-22.png'),
    bangali23_ld: require('./Assets/Images/Bangali/Bangali_LD-23.png'),
    bangali24_ld: require('./Assets/Images/Bangali/Bangali_LD-24.png'),
    bangali25_ld: require('./Assets/Images/Bangali/Bangali_LD-25.png'),
    bangali26_ld: require('./Assets/Images/Bangali/Bangali_LD-26.png'),
    bangali27_ld: require('./Assets/Images/Bangali/Bangali_LD-27.png'),
    bangali28_ld: require('./Assets/Images/Bangali/Bangali_LD-28.png'),
    bangali29_ld: require('./Assets/Images/Bangali/Bangali_LD-29.png'),
    bangali30_ld: require('./Assets/Images/Bangali/Bangali_LD-30.png'),
    bangali31_ld: require('./Assets/Images/Bangali/Bangali_LD-31.png'),
    bangali32_ld: require('./Assets/Images/Bangali/Bangali_LD-32.jpg'),
    bangali33_ld: require('./Assets/Images/Bangali/Bangali_LD-33.jpg'),
    bangali34_ld: require('./Assets/Images/Bangali/Bangali_LD-34.jpg'),
    bangali35_ld: require('./Assets/Images/Bangali/Bangali_LD-35.jpg'),
    bangali36_ld: require('./Assets/Images/Bangali/Bangali_LD-36.jpg'),
    bangali37_ld: require('./Assets/Images/Bangali/Bangali_LD-37.jpg'),
    bangali38_ld: require('./Assets/Images/Bangali/Bangali_LD-38.jpg'),
    bangali39_ld: require('./Assets/Images/Bangali/Bangali_LD-39.jpg'),
    bangali40_ld: require('./Assets/Images/Bangali/Bangali_LD-40.jpg'),
    bangali41_ld: require('./Assets/Images/Bangali/Bangali_LD-41.jpg'),
    bangali42_ld: require('./Assets/Images/Bangali/Bangali_LD-42.jpg'),
    bangali43_ld: require('./Assets/Images/Bangali/Bangali_LD-43.jpg'),
    bangali44_ld: require('./Assets/Images/Bangali/Bangali_LD-44.jpg'),
    bangali45_ld: require('./Assets/Images/Bangali/Bangali_LD-45.jpg'),
    bangali46_ld: require('./Assets/Images/Bangali/Bangali_LD-46.jpg'),
    bangali47_ld: require('./Assets/Images/Bangali/Bangali_LD-47.jpg'),
    bangali48_ld: require('./Assets/Images/Bangali/Bangali_LD-48.jpg'),
    bangali49_ld: require('./Assets/Images/Bangali/Bangali_LD-49.jpg'),

    chinese1_hd: require('./Assets/Images/Chinese/Chinese_HD-1.jpg'),
    chinese2_hd: require('./Assets/Images/Chinese/Chinese_HD-2.jpg'),
    chinese3_hd: require('./Assets/Images/Chinese/Chinese_HD-3.jpg'),
    chinese4_hd: require('./Assets/Images/Chinese/Chinese_HD-4.jpg'),
    chinese5_hd: require('./Assets/Images/Chinese/Chinese_HD-5.jpg'),
    chinese6_hd: require('./Assets/Images/Chinese/Chinese_HD-6.jpg'),
    chinese_positive_emotions_1_hd: require('./Assets/Images/Chinese/3_Positive-Emotions-1-HD.jpg'),
    chinese_positive_emotions_2_hd: require('./Assets/Images/Chinese/3_Positive-Emotions-2-HD.jpg'),
    chinese7_hd: require('./Assets/Images/Chinese/Chinese_HD-7.png'),
    chinese8_hd: require('./Assets/Images/Chinese/Chinese_HD-8.png'),
    chinese9_hd: require('./Assets/Images/Chinese/Chinese_HD-9.png'),
    chinese10_hd: require('./Assets/Images/Chinese/Chinese_HD-10.pdf'),
    chinese11_hd: require('./Assets/Images/Chinese/Chinese_HD-11.pdf'),
    chinese12_hd: require('./Assets/Images/Chinese/Chinese_HD-12.jpg'),
    chinese13_hd: require('./Assets/Images/Chinese/Chinese_HD-13.pdf'),
    chinese14_hd: require('./Assets/Images/Chinese/Chinese_HD-14.pdf'),
    chinese15_hd: require('./Assets/Images/Chinese/Chinese_HD-15.pdf'),
    chinese16_hd: require('./Assets/Images/Chinese/Chinese_HD-16.pdf'),
    chinese17_hd: require('./Assets/Images/Chinese/Chinese_HD-17.pdf'),
    chinese18_hd: require('./Assets/Images/Chinese/Chinese_HD-18.pdf'),
    chinese19_hd: require('./Assets/Images/Chinese/Chinese_HD-19.pdf'),
    chinese20_hd: require('./Assets/Images/Chinese/Chinese_HD-20.pdf'),
    chinese21_hd: require('./Assets/Images/Chinese/Chinese_HD-21.pdf'),
    chinese22_hd: require('./Assets/Images/Chinese/Chinese_HD-22.pdf'),
    chinese23_hd: require('./Assets/Images/Chinese/Chinese_HD-23.pdf'),
    chinese24_hd: require('./Assets/Images/Chinese/Chinese_HD-24.pdf'),
    chinese25_hd: require('./Assets/Images/Chinese/Chinese_HD-25.pdf'),
    chinese26_hd: require('./Assets/Images/Chinese/Chinese_HD-26.pdf'),
    chinese27_hd: require('./Assets/Images/Chinese/Chinese_HD-27.pdf'),
    chinese28_hd: require('./Assets/Images/Chinese/Chinese_HD-28.pdf'),
    chinese29_hd: require('./Assets/Images/Chinese/Chinese_HD-29.pdf'),
    chinese30_hd: require('./Assets/Images/Chinese/Chinese_HD-30.pdf'),
    chinese31_hd: require('./Assets/Images/Chinese/Chinese_HD-31.pdf'),
    chinese32_hd: require('./Assets/Images/Chinese/Chinese_HD-32.pdf'),
    chinese33_hd: require('./Assets/Images/Chinese/Chinese_HD-33.pdf'),
    chinese34_hd: require('./Assets/Images/Chinese/Chinese_HD-34.pdf'),
    chinese35_hd: require('./Assets/Images/Chinese/Chinese_HD-35.pdf'),
    chinese36_hd: require('./Assets/Images/Chinese/Chinese_HD-36.pdf'),
    chinese37_hd: require('./Assets/Images/Chinese/Chinese_HD-37.pdf'),
    chinese38_hd: require('./Assets/Images/Chinese/Chinese_HD-38.pdf'),
    chinese39_hd: require('./Assets/Images/Chinese/Chinese_HD-39.pdf'),
    chinese40_hd: require('./Assets/Images/Chinese/Chinese_HD-40.pdf'),
    chinese41_hd: require('./Assets/Images/Chinese/Chinese_HD-41.pdf'),
    chinese42_hd: require('./Assets/Images/Chinese/Chinese_HD-42.pdf'),
    chinese43_hd: require('./Assets/Images/Chinese/Chinese_HD-43.pdf'),
    chinese44_hd: require('./Assets/Images/Chinese/Chinese_HD-44.pdf'),
    chinese45_hd: require('./Assets/Images/Chinese/Chinese_HD-45.pdf'),
    chinese46_hd: require('./Assets/Images/Chinese/Chinese_HD-46.pdf'),
    chinese47_hd: require('./Assets/Images/Chinese/Chinese_HD-47.pdf'),
    chinese48_hd: require('./Assets/Images/Chinese/Chinese_HD-48.pdf'),

    chinese1_ld: require('./Assets/Images/Chinese/Chinese_LD-1.jpg'),
    chinese2_ld: require('./Assets/Images/Chinese/Chinese_LD-2.jpg'),
    chinese3_ld: require('./Assets/Images/Chinese/Chinese_LD-3.jpg'),
    chinese4_ld: require('./Assets/Images/Chinese/Chinese_LD-4.jpg'),
    chinese5_ld: require('./Assets/Images/Chinese/Chinese_LD-5.jpg'),
    chinese6_ld: require('./Assets/Images/Chinese/Chinese_LD-6.jpg'),
    chinese_positive_emotions_1_ld: require('./Assets/Images/Chinese/3_Positive-Emotions-1-LD.jpg'),
    chinese_positive_emotions_2_ld: require('./Assets/Images/Chinese/3_Positive-Emotions-2-LD.jpg'),
    chinese7_ld: require('./Assets/Images/Chinese/Chinese_LD-7.png'),
    chinese8_ld: require('./Assets/Images/Chinese/Chinese_LD-8.png'),
    chinese9_ld: require('./Assets/Images/Chinese/Chinese_LD-9.png'),
    chinese10_ld: require('./Assets/Images/Chinese/Chinese_LD-10.png'),
    chinese11_ld: require('./Assets/Images/Chinese/Chinese_LD-11.png'),
    chinese12_ld: require('./Assets/Images/Chinese/Chinese_LD-12.jpg'),
    chinese13_ld: require('./Assets/Images/Chinese/Chinese_LD-13.png'),
    chinese14_ld: require('./Assets/Images/Chinese/Chinese_LD-14.png'),
    chinese15_ld: require('./Assets/Images/Chinese/Chinese_LD-15.png'),
    chinese16_ld: require('./Assets/Images/Chinese/Chinese_LD-16.png'),
    chinese17_ld: require('./Assets/Images/Chinese/Chinese_LD-17.png'),
    chinese18_ld: require('./Assets/Images/Chinese/Chinese_LD-18.png'),
    chinese19_ld: require('./Assets/Images/Chinese/Chinese_LD-19.png'),
    chinese20_ld: require('./Assets/Images/Chinese/Chinese_LD-20.png'),
    chinese21_ld: require('./Assets/Images/Chinese/Chinese_LD-21.png'),
    chinese22_ld: require('./Assets/Images/Chinese/Chinese_LD-22.png'),
    chinese23_ld: require('./Assets/Images/Chinese/Chinese_LD-23.png'),
    chinese24_ld: require('./Assets/Images/Chinese/Chinese_LD-24.png'),
    chinese25_ld: require('./Assets/Images/Chinese/Chinese_LD-25.png'),
    chinese26_ld: require('./Assets/Images/Chinese/Chinese_LD-26.png'),
    chinese27_ld: require('./Assets/Images/Chinese/Chinese_LD-27.png'),
    chinese28_ld: require('./Assets/Images/Chinese/Chinese_LD-28.png'),
    chinese29_ld: require('./Assets/Images/Chinese/Chinese_LD-29.png'),
    chinese30_ld: require('./Assets/Images/Chinese/Chinese_LD-30.png'),
    chinese31_ld: require('./Assets/Images/Chinese/Chinese_LD-31.png'),
    chinese32_ld: require('./Assets/Images/Chinese/Chinese_LD-32.jpg'),
    chinese33_ld: require('./Assets/Images/Chinese/Chinese_LD-33.jpg'),
    chinese34_ld: require('./Assets/Images/Chinese/Chinese_LD-34.jpg'),
    chinese35_ld: require('./Assets/Images/Chinese/Chinese_LD-35.jpg'),
    chinese36_ld: require('./Assets/Images/Chinese/Chinese_LD-36.jpg'),
    chinese37_ld: require('./Assets/Images/Chinese/Chinese_LD-37.jpg'),
    chinese38_ld: require('./Assets/Images/Chinese/Chinese_LD-38.jpg'),
    chinese39_ld: require('./Assets/Images/Chinese/Chinese_LD-39.jpg'),
    chinese40_ld: require('./Assets/Images/Chinese/Chinese_LD-40.jpg'),
    chinese41_ld: require('./Assets/Images/Chinese/Chinese_LD-41.jpg'),
    chinese42_ld: require('./Assets/Images/Chinese/Chinese_LD-42.jpg'),
    chinese43_ld: require('./Assets/Images/Chinese/Chinese_LD-43.jpg'),
    chinese44_ld: require('./Assets/Images/Chinese/Chinese_LD-44.jpg'),
    chinese45_ld: require('./Assets/Images/Chinese/Chinese_LD-45.jpg'),
    chinese46_ld: require('./Assets/Images/Chinese/Chinese_LD-46.jpg'),
    chinese47_ld: require('./Assets/Images/Chinese/Chinese_LD-47.jpg'),
    chinese48_ld: require('./Assets/Images/Chinese/Chinese_LD-48.jpg'),

    english1_hd: require('./Assets/Images/English/English_HD-1.png'),
    english2_hd: require('./Assets/Images/English/English_HD-2.png'),
    english3_hd: require('./Assets/Images/English/English_HD-3.png'),
    english4_hd: require('./Assets/Images/English/English_HD-4.jpg'),
    english5_hd: require('./Assets/Images/English/English_HD-5.jpg'),
    english6_hd: require('./Assets/Images/English/English_HD-6.jpg'),
    english7_hd: require('./Assets/Images/English/English_HD-7.png'),
    english8_hd: require('./Assets/Images/English/English_HD-8.png'),
    english9_hd: require('./Assets/Images/English/English_HD-9.png'),
    english10_hd: require('./Assets/Images/English/English_HD-10.pdf'),
    english11_hd: require('./Assets/Images/English/English_HD-11.pdf'),
    english12_hd: require('./Assets/Images/English/English_HD-12.pdf'),
    english13_hd: require('./Assets/Images/English/English_HD-13.pdf'),
    english14_hd: require('./Assets/Images/English/English_HD-14.pdf'),
    english15_hd: require('./Assets/Images/English/English_HD-15.pdf'),
    english16_hd: require('./Assets/Images/English/English_HD-16.pdf'),
    english17_hd: require('./Assets/Images/English/English_HD-17.pdf'),
    english18_hd: require('./Assets/Images/English/English_HD-18.pdf'),
    english19_hd: require('./Assets/Images/English/English_HD-19.pdf'),
    english20_hd: require('./Assets/Images/English/English_HD-20.pdf'),
    english21_hd: require('./Assets/Images/English/English_HD-21.pdf'),
    english22_hd: require('./Assets/Images/English/English_HD-22.pdf'),
    english23_hd: require('./Assets/Images/English/English_HD-23.pdf'),
    english24_hd: require('./Assets/Images/English/English_HD-24.pdf'),
    english25_hd: require('./Assets/Images/English/English_HD-25.pdf'),
    english26_hd: require('./Assets/Images/English/English_HD-26.pdf'),
    english27_hd: require('./Assets/Images/English/English_HD-27.pdf'),
    english28_hd: require('./Assets/Images/English/English_HD-28.pdf'),
    english29_hd: require('./Assets/Images/English/English_HD-29.pdf'),
    english30_hd: require('./Assets/Images/English/English_HD-30.pdf'),
    english31_hd: require('./Assets/Images/English/English_HD-31.jpg'),
    english32_hd: require('./Assets/Images/English/English_HD-32.pdf'),
    english33_hd: require('./Assets/Images/English/English_HD-33.pdf'),
    english34_hd: require('./Assets/Images/English/English_HD-34.pdf'),
    english35_hd: require('./Assets/Images/English/English_HD-35.pdf'),
    english36_hd: require('./Assets/Images/English/English_HD-36.pdf'),
    english37_hd: require('./Assets/Images/English/English_HD-37.png'),
    english38_hd: require('./Assets/Images/English/English_HD-38.pdf'),
    english39_hd: require('./Assets/Images/English/English_HD-39.pdf'),
    english40_hd: require('./Assets/Images/English/English_HD-40.pdf'),
    english41_hd: require('./Assets/Images/English/English_HD-41.pdf'),
    english42_hd: require('./Assets/Images/English/English_HD-42.pdf'),
    english43_hd: require('./Assets/Images/English/English_HD-43.pdf'),
    english44_hd: require('./Assets/Images/English/English_HD-44.pdf'),
    english45_hd: require('./Assets/Images/English/English_HD-45.pdf'),
    english46_hd: require('./Assets/Images/English/English_HD-46.pdf'),
    english47_hd: require('./Assets/Images/English/English_HD-47.pdf'),
    english48_hd: require('./Assets/Images/English/English_HD-48.pdf'),
    english49_hd: require('./Assets/Images/English/English_HD-49.pdf'),
    english50_hd: require('./Assets/Images/English/English_HD-50.pdf'),
    english51_hd: require('./Assets/Images/English/English_HD-51.pdf'),
    english52_hd: require('./Assets/Images/English/English_HD-52.pdf'),
    english53_hd: require('./Assets/Images/English/English_HD-53.pdf'),
    english54_hd: require('./Assets/Images/English/English_HD-54.pdf'),
    english55_hd: require('./Assets/Images/English/English_HD-55.pdf'),
    english56_hd: require('./Assets/Images/English/English_HD-56.pdf'),
    english57_hd: require('./Assets/Images/English/English_HD-57.pdf'),
    english58_hd: require('./Assets/Images/English/English_HD-58.pdf'),
    english59_hd: require('./Assets/Images/English/English_HD-59.pdf'),
    english60_hd: require('./Assets/Images/English/English_HD-60.pdf'),

    english1_ld: require('./Assets/Images/English/English_LD-1.png'),
    english2_ld: require('./Assets/Images/English/English_LD-2.png'),
    english3_ld: require('./Assets/Images/English/English_LD-3.png'),
    english4_ld: require('./Assets/Images/English/English_LD-4.jpg'),
    english5_ld: require('./Assets/Images/English/English_LD-5.jpg'),
    english6_ld: require('./Assets/Images/English/English_LD-6.jpg'),
    english7_ld: require('./Assets/Images/English/English_LD-7.png'),
    english8_ld: require('./Assets/Images/English/English_LD-8.png'),
    english9_ld: require('./Assets/Images/English/English_LD-9.png'),
    english10_ld: require('./Assets/Images/English/TBT.png'),
    english22_ld: require('./Assets/Images/English/English_LD-22.png'),
    english23_ld: require('./Assets/Images/English/English_LD-23.png'),
    english24_ld: require('./Assets/Images/English/English_LD-24.png'),
    english25_ld: require('./Assets/Images/English/English_LD-25.png'),
    english26_ld: require('./Assets/Images/English/English_LD-26.png'),
    english27_ld: require('./Assets/Images/English/English_LD-27.png'),
    english28_ld: require('./Assets/Images/English/English_LD-28.png'),
    english29_ld: require('./Assets/Images/English/English_LD-29.png'),
    english30_ld: require('./Assets/Images/English/English_LD-30.png'),
    english31_ld: require('./Assets/Images/English/English_LD-31.jpg'),
    english32_ld: require('./Assets/Images/English/English_LD-32.png'),
    english33_ld: require('./Assets/Images/English/English_LD-33.png'),
    english34_ld: require('./Assets/Images/English/English_LD-34.png'),
    english35_ld: require('./Assets/Images/English/English_LD-35.png'),
    english36_ld: require('./Assets/Images/English/English_LD-36.png'),
    english37_ld: require('./Assets/Images/English/English_LD-37.png'),
    english38_ld: require('./Assets/Images/English/English_LD-38.png'),
    english39_ld: require('./Assets/Images/English/English_LD-39.png'),
    english40_ld: require('./Assets/Images/English/English_LD-40.png'),
    english41_ld: require('./Assets/Images/English/English_LD-41.png'),
    english42_ld: require('./Assets/Images/English/English_LD-42.png'),
    english43_ld: require('./Assets/Images/English/English_LD-43.png'),
    english44_ld: require('./Assets/Images/English/English_LD-44.jpg'),
    english45_ld: require('./Assets/Images/English/English_LD-45.jpg'),
    english46_ld: require('./Assets/Images/English/English_LD-46.jpg'),
    english47_ld: require('./Assets/Images/English/English_LD-47.jpg'),
    english48_ld: require('./Assets/Images/English/English_LD-48.jpg'),
    english49_ld: require('./Assets/Images/English/English_LD-49.jpg'),
    english50_ld: require('./Assets/Images/English/English_LD-50.jpg'),
    english51_ld: require('./Assets/Images/English/English_LD-51.jpg'),
    english52_ld: require('./Assets/Images/English/English_LD-52.jpg'),
    english53_ld: require('./Assets/Images/English/English_LD-53.jpg'),
    english54_ld: require('./Assets/Images/English/English_LD-54.jpg'),
    english55_ld: require('./Assets/Images/English/English_LD-55.jpg'),
    english56_ld: require('./Assets/Images/English/English_LD-56.jpg'),
    english57_ld: require('./Assets/Images/English/English_LD-57.jpg'),
    english58_ld: require('./Assets/Images/English/English_LD-58.jpg'),
    english59_ld: require('./Assets/Images/English/English_LD-59.jpg'),
    english60_ld: require('./Assets/Images/English/English_LD-60.jpg'),

    hindi1_hd: require('./Assets/Images/Hindi/Hindi_HD-1.jpg'),
    hindi2_hd: require('./Assets/Images/Hindi/Hindi_HD-2.jpg'),
    hindi3_hd: require('./Assets/Images/Hindi/Hindi_HD-3.jpg'),
    hindi4_hd: require('./Assets/Images/Hindi/Hindi_HD-4.jpg'),
    hindi5_hd: require('./Assets/Images/Hindi/Hindi_HD-5.jpg'),
    hindi6_hd: require('./Assets/Images/Hindi/Hindi_HD-6.jpg'),
    hindi7_hd: require('./Assets/Images/Hindi/Hindi_HD-7.png'),
    hindi8_hd: require('./Assets/Images/Hindi/Hindi_HD-8.png'),
    hindi9_hd: require('./Assets/Images/Hindi/Hindi_HD-9.png'),
    hindi10_hd: require('./Assets/Images/Hindi/Hindi_HD-10.pdf'),
    hindi11_hd: require('./Assets/Images/Hindi/Hindi_HD-11.pdf'),
    hindi12_hd: require('./Assets/Images/Hindi/Hindi_HD-12.jpg'),
    hindi13_hd: require('./Assets/Images/Hindi/Hindi_HD-13.pdf'),
    hindi14_hd: require('./Assets/Images/Hindi/Hindi_HD-14.pdf'),
    hindi15_hd: require('./Assets/Images/Hindi/Hindi_HD-15.pdf'),
    hindi16_hd: require('./Assets/Images/Hindi/Hindi_HD-16.pdf'),
    hindi17_hd: require('./Assets/Images/Hindi/Hindi_HD-17.pdf'),
    hindi18_hd: require('./Assets/Images/Hindi/Hindi_HD-18.pdf'),
    hindi19_hd: require('./Assets/Images/Hindi/Hindi_HD-19.pdf'),
    hindi20_hd: require('./Assets/Images/Hindi/Hindi_HD-20.pdf'),
    hindi21_hd: require('./Assets/Images/Hindi/Hindi_HD-21.pdf'),
    hindi22_hd: require('./Assets/Images/Hindi/Hindi_HD-22.pdf'),
    hindi23_hd: require('./Assets/Images/Hindi/Hindi_HD-23.pdf'),
    hindi24_hd: require('./Assets/Images/Hindi/Hindi_HD-24.png'),
    hindi25_hd: require('./Assets/Images/Hindi/Hindi_HD-25.pdf'),
    hindi26_hd: require('./Assets/Images/Hindi/Hindi_HD-26.pdf'),
    hindi27_hd: require('./Assets/Images/Hindi/Hindi_HD-27.pdf'),
    hindi28_hd: require('./Assets/Images/Hindi/Hindi_HD-28.pdf'),
    hindi29_hd: require('./Assets/Images/Hindi/Hindi_HD-29.pdf'),
    hindi30_hd: require('./Assets/Images/Hindi/Hindi_HD-30.pdf'),
    hindi31_hd: require('./Assets/Images/Hindi/Hindi_HD-31.pdf'),
    hindi32_hd: require('./Assets/Images/Hindi/Hindi_HD-32.pdf'),
    hindi33_hd: require('./Assets/Images/Hindi/Hindi_HD-33.pdf'),
    hindi34_hd: require('./Assets/Images/Hindi/Hindi_HD-34.pdf'),
    hindi35_hd: require('./Assets/Images/Hindi/Hindi_HD-35.pdf'),
    hindi36_hd: require('./Assets/Images/Hindi/Hindi_HD-36.pdf'),
    hindi37_hd: require('./Assets/Images/Hindi/Hindi_HD-37.pdf'),
    hindi38_hd: require('./Assets/Images/Hindi/Hindi_HD-38.pdf'),
    hindi39_hd: require('./Assets/Images/Hindi/Hindi_HD-39.pdf'),
    hindi40_hd: require('./Assets/Images/Hindi/Hindi_HD-40.pdf'),
    hindi41_hd: require('./Assets/Images/Hindi/Hindi_HD-41.pdf'),
    hindi42_hd: require('./Assets/Images/Hindi/Hindi_HD-42.pdf'),
    hindi43_hd: require('./Assets/Images/Hindi/Hindi_HD-43.pdf'),
    hindi44_hd: require('./Assets/Images/Hindi/Hindi_HD-44.pdf'),
    hindi45_hd: require('./Assets/Images/Hindi/Hindi_HD-45.pdf'),
    hindi46_hd: require('./Assets/Images/Hindi/Hindi_HD-46.pdf'),
    hindi47_hd: require('./Assets/Images/Hindi/Hindi_HD-47.pdf'),
    hindi48_hd: require('./Assets/Images/Hindi/Hindi_HD-48.pdf'),
    hindi49_hd: require('./Assets/Images/Hindi/Hindi_HD-49.pdf'),

    hindi1_ld: require('./Assets/Images/Hindi/Hindi_LD-1.jpg'),
    hindi2_ld: require('./Assets/Images/Hindi/Hindi_LD-2.jpg'),
    hindi3_ld: require('./Assets/Images/Hindi/Hindi_LD-3.jpg'),
    hindi4_ld: require('./Assets/Images/Hindi/Hindi_LD-4.jpg'),
    hindi5_ld: require('./Assets/Images/Hindi/Hindi_LD-5.jpg'),
    hindi6_ld: require('./Assets/Images/Hindi/Hindi_LD-6.jpg'),
    hindi7_ld: require('./Assets/Images/Hindi/Hindi_LD-7.png'),
    hindi8_ld: require('./Assets/Images/Hindi/Hindi_LD-8.png'),
    hindi9_ld: require('./Assets/Images/Hindi/Hindi_LD-9.png'),
    hindi10_ld: require('./Assets/Images/Hindi/Hindi_LD-10.png'),
    hindi11_ld: require('./Assets/Images/Hindi/Hindi_LD-11.png'),
    hindi12_ld: require('./Assets/Images/Hindi/Hindi_LD-12.jpg'),
    hindi13_ld: require('./Assets/Images/Hindi/Hindi_LD-13.png'),
    hindi14_ld: require('./Assets/Images/Hindi/Hindi_LD-14.png'),
    hindi15_ld: require('./Assets/Images/Hindi/Hindi_LD-15.png'),
    hindi16_ld: require('./Assets/Images/Hindi/Hindi_LD-16.png'),
    hindi17_ld: require('./Assets/Images/Hindi/Hindi_LD-17.png'),
    hindi18_ld: require('./Assets/Images/Hindi/Hindi_LD-18.png'),
    hindi19_ld: require('./Assets/Images/Hindi/Hindi_LD-19.png'),
    hindi20_ld: require('./Assets/Images/Hindi/Hindi_LD-20.png'),
    hindi21_ld: require('./Assets/Images/Hindi/Hindi_LD-21.png'),
    hindi22_ld: require('./Assets/Images/Hindi/Hindi_LD-22.png'),
    hindi23_ld: require('./Assets/Images/Hindi/Hindi_LD-23.png'),
    hindi24_ld: require('./Assets/Images/Hindi/Hindi_LD-24.png'),
    hindi25_ld: require('./Assets/Images/Hindi/Hindi_LD-25.png'),
    hindi26_ld: require('./Assets/Images/Hindi/Hindi_LD-26.png'),
    hindi27_ld: require('./Assets/Images/Hindi/Hindi_LD-27.png'),
    hindi28_ld: require('./Assets/Images/Hindi/Hindi_LD-28.png'),
    hindi29_ld: require('./Assets/Images/Hindi/Hindi_LD-29.png'),
    hindi30_ld: require('./Assets/Images/Hindi/Hindi_LD-30.png'),
    hindi31_ld: require('./Assets/Images/Hindi/Hindi_LD-31.png'),
    hindi32_ld: require('./Assets/Images/Hindi/Hindi_LD-32.jpg'),
    hindi33_ld: require('./Assets/Images/Hindi/Hindi_LD-33.jpg'),
    hindi34_ld: require('./Assets/Images/Hindi/Hindi_LD-34.jpg'),
    hindi35_ld: require('./Assets/Images/Hindi/Hindi_LD-35.jpg'),
    hindi36_ld: require('./Assets/Images/Hindi/Hindi_LD-36.jpg'),
    hindi37_ld: require('./Assets/Images/Hindi/Hindi_LD-37.jpg'),
    hindi38_ld: require('./Assets/Images/Hindi/Hindi_LD-38.jpg'),
    hindi39_ld: require('./Assets/Images/Hindi/Hindi_LD-39.jpg'),
    hindi40_ld: require('./Assets/Images/Hindi/Hindi_LD-40.jpg'),
    hindi41_ld: require('./Assets/Images/Hindi/Hindi_LD-41.jpg'),
    hindi42_ld: require('./Assets/Images/Hindi/Hindi_LD-42.jpg'),
    hindi43_ld: require('./Assets/Images/Hindi/Hindi_LD-43.jpg'),
    hindi44_ld: require('./Assets/Images/Hindi/Hindi_LD-44.jpg'),
    hindi45_ld: require('./Assets/Images/Hindi/Hindi_LD-45.jpg'),
    hindi46_ld: require('./Assets/Images/Hindi/Hindi_LD-46.jpg'),
    hindi47_ld: require('./Assets/Images/Hindi/Hindi_LD-47.jpg'),
    hindi48_ld: require('./Assets/Images/Hindi/Hindi_LD-48.jpg'),
    hindi49_ld: require('./Assets/Images/Hindi/Hindi_LD-49.jpg'),

    tagalog1_hd: require('./Assets/Images/Tagalog/Tagalog_HD-1.jpg'),
    tagalog2_hd: require('./Assets/Images/Tagalog/Tagalog_HD-2.jpg'),
    tagalog3_hd: require('./Assets/Images/Tagalog/Tagalog_HD-3.jpg'),
    tagalog4_hd: require('./Assets/Images/Tagalog/Tagalog_HD-4.jpg'),
    tagalog5_hd: require('./Assets/Images/Tagalog/Tagalog_HD-5.jpg'),
    tagalog6_hd: require('./Assets/Images/Tagalog/Tagalog_HD-6.jpg'),
    tagalog7_hd: require('./Assets/Images/Tagalog/Tagalog_HD-7.png'),
    tagalog8_hd: require('./Assets/Images/Tagalog/Tagalog_HD-8.png'),
    tagalog9_hd: require('./Assets/Images/Tagalog/Tagalog_HD-9.png'),
    tagalog10_hd: require('./Assets/Images/Tagalog/Tagalog_HD-10.pdf'),
    tagalog11_hd: require('./Assets/Images/Tagalog/Tagalog_HD-11.pdf'),
    tagalog12_hd: require('./Assets/Images/Tagalog/Tagalog_HD-12.jpg'),
    tagalog13_hd: require('./Assets/Images/Tagalog/Tagalog_HD-13.pdf'),
    tagalog14_hd: require('./Assets/Images/Tagalog/Tagalog_HD-14.pdf'),
    tagalog15_hd: require('./Assets/Images/Tagalog/Tagalog_HD-15.pdf'),
    tagalog16_hd: require('./Assets/Images/Tagalog/Tagalog_HD-16.pdf'),
    tagalog17_hd: require('./Assets/Images/Tagalog/Tagalog_HD-17.pdf'),
    tagalog18_hd: require('./Assets/Images/Tagalog/Tagalog_HD-18.pdf'),
    tagalog19_hd: require('./Assets/Images/Tagalog/Tagalog_HD-19.pdf'),
    tagalog20_hd: require('./Assets/Images/Tagalog/Tagalog_HD-20.pdf'),
    tagalog21_hd: require('./Assets/Images/Tagalog/Tagalog_HD-21.pdf'),
    tagalog22_hd: require('./Assets/Images/Tagalog/Tagalog_HD-22.pdf'),
    tagalog23_hd: require('./Assets/Images/Tagalog/Tagalog_HD-23.pdf'),
    tagalog24_hd: require('./Assets/Images/Tagalog/Tagalog_HD-24.pdf'),
    tagalog25_hd: require('./Assets/Images/Tagalog/Tagalog_HD-25.pdf'),
    tagalog26_hd: require('./Assets/Images/Tagalog/Tagalog_HD-26.pdf'),
    tagalog27_hd: require('./Assets/Images/Tagalog/Tagalog_HD-27.pdf'),
    tagalog28_hd: require('./Assets/Images/Tagalog/Tagalog_HD-28.pdf'),
    tagalog29_hd: require('./Assets/Images/Tagalog/Tagalog_HD-29.pdf'),
    tagalog30_hd: require('./Assets/Images/Tagalog/Tagalog_HD-30.pdf'),
    tagalog31_hd: require('./Assets/Images/Tagalog/Tagalog_HD-31.pdf'),
    tagalog32_hd: require('./Assets/Images/Tagalog/Tagalog_HD-32.pdf'),
    tagalog33_hd: require('./Assets/Images/Tagalog/Tagalog_HD-33.pdf'),
    tagalog34_hd: require('./Assets/Images/Tagalog/Tagalog_HD-34.pdf'),
    tagalog35_hd: require('./Assets/Images/Tagalog/Tagalog_HD-35.pdf'),
    tagalog36_hd: require('./Assets/Images/Tagalog/Tagalog_HD-36.pdf'),
    tagalog37_hd: require('./Assets/Images/Tagalog/Tagalog_HD-37.pdf'),
    tagalog38_hd: require('./Assets/Images/Tagalog/Tagalog_HD-38.pdf'),
    tagalog39_hd: require('./Assets/Images/Tagalog/Tagalog_HD-39.pdf'),
    tagalog40_hd: require('./Assets/Images/Tagalog/Tagalog_HD-40.pdf'),
    tagalog41_hd: require('./Assets/Images/Tagalog/Tagalog_HD-41.pdf'),
    tagalog42_hd: require('./Assets/Images/Tagalog/Tagalog_HD-42.pdf'),
    tagalog43_hd: require('./Assets/Images/Tagalog/Tagalog_HD-43.pdf'),
    tagalog44_hd: require('./Assets/Images/Tagalog/Tagalog_HD-44.pdf'),
    tagalog45_hd: require('./Assets/Images/Tagalog/Tagalog_HD-45.pdf'),
    tagalog46_hd: require('./Assets/Images/Tagalog/Tagalog_HD-46.pdf'),
    tagalog47_hd: require('./Assets/Images/Tagalog/Tagalog_HD-47.pdf'),
    tagalog48_hd: require('./Assets/Images/Tagalog/Tagalog_HD-48.pdf'),
    

    tagalog1_ld: require('./Assets/Images/Tagalog/Tagalog_LD-1.jpg'),
    tagalog2_ld: require('./Assets/Images/Tagalog/Tagalog_LD-2.jpg'),
    tagalog3_ld: require('./Assets/Images/Tagalog/Tagalog_LD-3.jpg'),
    tagalog4_ld: require('./Assets/Images/Tagalog/Tagalog_LD-4.jpg'),
    tagalog5_ld: require('./Assets/Images/Tagalog/Tagalog_LD-5.jpg'),
    tagalog6_ld: require('./Assets/Images/Tagalog/Tagalog_LD-6.jpg'),
    tagalog7_ld: require('./Assets/Images/Tagalog/Tagalog_LD-7.png'),
    tagalog8_ld: require('./Assets/Images/Tagalog/Tagalog_LD-8.png'),
    tagalog9_ld: require('./Assets/Images/Tagalog/Tagalog_LD-9.png'),
    tagalog10_ld: require('./Assets/Images/Tagalog/Tagalog_LD-10.png'),
    tagalog11_ld: require('./Assets/Images/Tagalog/Tagalog_LD-11.png'),
    tagalog12_ld: require('./Assets/Images/Tagalog/Tagalog_LD-12.jpg'),
    tagalog13_ld: require('./Assets/Images/Tagalog/Tagalog_LD-13.png'),
    tagalog14_ld: require('./Assets/Images/Tagalog/Tagalog_LD-14.png'),
    tagalog15_ld: require('./Assets/Images/Tagalog/Tagalog_LD-15.png'),
    tagalog16_ld: require('./Assets/Images/Tagalog/Tagalog_LD-16.png'),
    tagalog17_ld: require('./Assets/Images/Tagalog/Tagalog_LD-17.png'),
    tagalog18_ld: require('./Assets/Images/Tagalog/Tagalog_LD-18.png'),
    tagalog19_ld: require('./Assets/Images/Tagalog/Tagalog_LD-19.png'),
    tagalog20_ld: require('./Assets/Images/Tagalog/Tagalog_LD-20.png'),
    tagalog21_ld: require('./Assets/Images/Tagalog/Tagalog_LD-21.png'),
    tagalog22_ld: require('./Assets/Images/Tagalog/Tagalog_LD-22.png'),
    tagalog23_ld: require('./Assets/Images/Tagalog/Tagalog_LD-23.png'),
    tagalog24_ld: require('./Assets/Images/Tagalog/Tagalog_LD-24.png'),
    tagalog25_ld: require('./Assets/Images/Tagalog/Tagalog_LD-25.png'),
    tagalog26_ld: require('./Assets/Images/Tagalog/Tagalog_LD-26.png'),
    tagalog27_ld: require('./Assets/Images/Tagalog/Tagalog_LD-27.jpg'),
    tagalog28_ld: require('./Assets/Images/Tagalog/Tagalog_LD-28.jpg'),
    tagalog29_ld: require('./Assets/Images/Tagalog/Tagalog_LD-29.jpg'),
    tagalog30_ld: require('./Assets/Images/Tagalog/Tagalog_LD-30.jpg'),
    tagalog31_ld: require('./Assets/Images/Tagalog/Tagalog_LD-31.jpg'),
    tagalog32_ld: require('./Assets/Images/Tagalog/Tagalog_LD-32.jpg'),
    tagalog33_ld: require('./Assets/Images/Tagalog/Tagalog_LD-33.jpg'),
    tagalog34_ld: require('./Assets/Images/Tagalog/Tagalog_LD-34.jpg'),
    tagalog35_ld: require('./Assets/Images/Tagalog/Tagalog_LD-35.jpg'),
    tagalog36_ld: require('./Assets/Images/Tagalog/Tagalog_LD-36.jpg'),
    tagalog37_ld: require('./Assets/Images/Tagalog/Tagalog_LD-37.jpg'),
    tagalog38_ld: require('./Assets/Images/Tagalog/Tagalog_LD-38.jpg'),
    tagalog39_ld: require('./Assets/Images/Tagalog/Tagalog_LD-39.jpg'),
    tagalog40_ld: require('./Assets/Images/Tagalog/Tagalog_LD-40.jpg'),
    tagalog41_ld: require('./Assets/Images/Tagalog/Tagalog_LD-41.jpg'),
    tagalog42_ld: require('./Assets/Images/Tagalog/Tagalog_LD-42.jpg'),
    tagalog43_ld: require('./Assets/Images/Tagalog/Tagalog_LD-43.jpg'),
    tagalog44_ld: require('./Assets/Images/Tagalog/Tagalog_LD-44.jpg'),
    tagalog45_ld: require('./Assets/Images/Tagalog/Tagalog_LD-45.jpg'),
    tagalog46_ld: require('./Assets/Images/Tagalog/Tagalog_LD-46.jpg'),
    tagalog47_ld: require('./Assets/Images/Tagalog/Tagalog_LD-47.jpg'),
    tagalog48_ld: require('./Assets/Images/Tagalog/Tagalog_LD-48.jpg'),

    urdu1_hd: require('./Assets/Images/Urdu/Urdu_HD-1.jpg'),
    urdu2_hd: require('./Assets/Images/Urdu/Urdu_HD-2.jpg'),
    urdu3_hd: require('./Assets/Images/Urdu/Urdu_HD-3.jpg'),
    urdu4_hd: require('./Assets/Images/Urdu/Urdu_HD-4.jpg'),
    urdu5_hd: require('./Assets/Images/Urdu/Urdu_HD-5.jpg'),
    urdu6_hd: require('./Assets/Images/Urdu/Urdu_HD-6.jpg'),
    urdu7_hd: require('./Assets/Images/Urdu/Urdu_HD-7.png'),
    urdu8_hd: require('./Assets/Images/Urdu/Urdu_HD-8.png'),
    urdu9_hd: require('./Assets/Images/Urdu/Urdu_HD-9.png'),
    urdu10_hd: require('./Assets/Images/Urdu/Urdu_HD-10.pdf'),
    urdu11_hd: require('./Assets/Images/Urdu/Urdu_HD-11.pdf'),
    urdu12_hd: require('./Assets/Images/Urdu/Urdu_HD-12.jpg'),
    urdu13_hd: require('./Assets/Images/Urdu/Urdu_HD-13.pdf'),
    urdu14_hd: require('./Assets/Images/Urdu/Urdu_HD-14.pdf'),
    urdu15_hd: require('./Assets/Images/Urdu/Urdu_HD-15.pdf'),
    urdu16_hd: require('./Assets/Images/Urdu/Urdu_HD-16.pdf'),
    urdu17_hd: require('./Assets/Images/Urdu/Urdu_HD-17.pdf'),
    urdu18_hd: require('./Assets/Images/Urdu/Urdu_HD-18.pdf'),
    urdu19_hd: require('./Assets/Images/Urdu/Urdu_HD-19.pdf'),
    urdu20_hd: require('./Assets/Images/Urdu/Urdu_HD-20.pdf'),
    urdu21_hd: require('./Assets/Images/Urdu/Urdu_HD-21.pdf'),
    urdu22_hd: require('./Assets/Images/Urdu/Urdu_HD-22.pdf'),
    urdu23_hd: require('./Assets/Images/Urdu/Urdu_HD-23.pdf'),
    urdu24_hd: require('./Assets/Images/Urdu/Urdu_HD-24.pdf'),
    urdu25_hd: require('./Assets/Images/Urdu/Urdu_HD-25.pdf'),
    urdu26_hd: require('./Assets/Images/Urdu/Urdu_HD-26.pdf'),
    urdu27_hd: require('./Assets/Images/Urdu/Urdu_HD-27.png'),
    urdu28_hd: require('./Assets/Images/Urdu/Urdu_HD-28.pdf'),
    urdu29_hd: require('./Assets/Images/Urdu/Urdu_HD-29.pdf'),
    urdu30_hd: require('./Assets/Images/Urdu/Urdu_HD-30.pdf'),
    urdu31_hd: require('./Assets/Images/Urdu/Urdu_HD-31.pdf'),
    urdu32_hd: require('./Assets/Images/Urdu/Urdu_HD-32.pdf'),
    urdu33_hd: require('./Assets/Images/Urdu/Urdu_HD-33.pdf'),
    urdu34_hd: require('./Assets/Images/Urdu/Urdu_HD-34.pdf'),
    urdu35_hd: require('./Assets/Images/Urdu/Urdu_HD-35.pdf'),
    urdu36_hd: require('./Assets/Images/Urdu/Urdu_HD-36.pdf'),
    urdu37_hd: require('./Assets/Images/Urdu/Urdu_HD-37.pdf'),
    urdu38_hd: require('./Assets/Images/Urdu/Urdu_HD-38.pdf'),
    urdu39_hd: require('./Assets/Images/Urdu/Urdu_HD-39.pdf'),
    urdu40_hd: require('./Assets/Images/Urdu/Urdu_HD-40.pdf'),
    urdu41_hd: require('./Assets/Images/Urdu/Urdu_HD-41.pdf'),
    urdu42_hd: require('./Assets/Images/Urdu/Urdu_HD-42.pdf'),
    urdu43_hd: require('./Assets/Images/Urdu/Urdu_HD-43.pdf'),
    urdu44_hd: require('./Assets/Images/Urdu/Urdu_HD-44.pdf'),
    urdu45_hd: require('./Assets/Images/Urdu/Urdu_HD-45.pdf'),
    urdu46_hd: require('./Assets/Images/Urdu/Urdu_HD-46.pdf'),
    urdu47_hd: require('./Assets/Images/Urdu/Urdu_HD-47.pdf'),
    urdu48_hd: require('./Assets/Images/Urdu/Urdu_HD-48.pdf'),
    urdu49_hd: require('./Assets/Images/Urdu/Urdu_HD-49.pdf'),

    urdu1_ld: require('./Assets/Images/Urdu/Urdu_LD-1.jpg'),
    urdu2_ld: require('./Assets/Images/Urdu/Urdu_LD-2.jpg'),
    urdu3_ld: require('./Assets/Images/Urdu/Urdu_LD-3.jpg'),
    urdu4_ld: require('./Assets/Images/Urdu/Urdu_LD-4.jpg'),
    urdu5_ld: require('./Assets/Images/Urdu/Urdu_LD-5.jpg'),
    urdu6_ld: require('./Assets/Images/Urdu/Urdu_LD-6.jpg'),
    urdu7_ld: require('./Assets/Images/Urdu/Urdu_LD-7.png'),
    urdu8_ld: require('./Assets/Images/Urdu/Urdu_LD-8.png'),
    urdu9_ld: require('./Assets/Images/Urdu/Urdu_LD-9.png'),
    urdu10_ld: require('./Assets/Images/Urdu/Urdu_LD-10.png'),
    urdu11_ld: require('./Assets/Images/Urdu/Urdu_LD-11.png'),
    urdu12_ld: require('./Assets/Images/Urdu/Urdu_LD-12.jpg'),
    urdu13_ld: require('./Assets/Images/Urdu/Urdu_LD-13.png'),
    urdu14_ld: require('./Assets/Images/Urdu/Urdu_LD-14.png'),
    urdu15_ld: require('./Assets/Images/Urdu/Urdu_LD-15.png'),
    urdu16_ld: require('./Assets/Images/Urdu/Urdu_LD-16.png'),
    urdu17_ld: require('./Assets/Images/Urdu/Urdu_LD-17.png'),
    urdu18_ld: require('./Assets/Images/Urdu/Urdu_LD-18.png'),
    urdu19_ld: require('./Assets/Images/Urdu/Urdu_LD-19.png'),
    urdu20_ld: require('./Assets/Images/Urdu/Urdu_LD-20.png'),
    urdu21_ld: require('./Assets/Images/Urdu/Urdu_LD-21.png'),
    urdu22_ld: require('./Assets/Images/Urdu/Urdu_LD-22.png'),
    urdu23_ld: require('./Assets/Images/Urdu/Urdu_LD-23.png'),
    urdu24_ld: require('./Assets/Images/Urdu/Urdu_LD-24.png'),
    urdu25_ld: require('./Assets/Images/Urdu/Urdu_LD-25.png'),
    urdu26_ld: require('./Assets/Images/Urdu/Urdu_LD-26.png'),
    urdu27_ld: require('./Assets/Images/Urdu/Urdu_LD-27.png'),
    urdu28_ld: require('./Assets/Images/Urdu/Urdu_LD-28.png'),
    urdu29_ld: require('./Assets/Images/Urdu/Urdu_LD-29.jpg'),
    urdu30_ld: require('./Assets/Images/Urdu/Urdu_LD-30.jpg'),
    urdu31_ld: require('./Assets/Images/Urdu/Urdu_LD-31.jpg'),
    urdu32_ld: require('./Assets/Images/Urdu/Urdu_LD-32.jpg'),
    urdu33_ld: require('./Assets/Images/Urdu/Urdu_LD-33.jpg'),
    urdu34_ld: require('./Assets/Images/Urdu/Urdu_LD-34.jpg'),
    urdu35_ld: require('./Assets/Images/Urdu/Urdu_LD-35.jpg'),
    urdu36_ld: require('./Assets/Images/Urdu/Urdu_LD-36.jpg'),
    urdu37_ld: require('./Assets/Images/Urdu/Urdu_LD-37.jpg'),
    urdu38_ld: require('./Assets/Images/Urdu/Urdu_LD-38.jpg'),
    urdu39_ld: require('./Assets/Images/Urdu/Urdu_LD-39.jpg'),
    urdu40_ld: require('./Assets/Images/Urdu/Urdu_LD-40.jpg'),
    urdu41_ld: require('./Assets/Images/Urdu/Urdu_LD-41.jpg'),
    urdu42_ld: require('./Assets/Images/Urdu/Urdu_LD-42.jpg'),
    urdu43_ld: require('./Assets/Images/Urdu/Urdu_LD-43.jpg'),
    urdu44_ld: require('./Assets/Images/Urdu/Urdu_LD-44.jpg'),
    urdu45_ld: require('./Assets/Images/Urdu/Urdu_LD-45.jpg'),
    urdu46_ld: require('./Assets/Images/Urdu/Urdu_LD-46.jpg'),
    urdu47_ld: require('./Assets/Images/Urdu/Urdu_LD-47.jpg'),
    urdu48_ld: require('./Assets/Images/Urdu/Urdu_LD-48.jpg'),
    urdu49_ld: require('./Assets/Images/Urdu/Urdu_LD-49.jpg'),

    english: require('./Assets/Images/english.svg').default,
    pakistan: require('./Assets/Images/pakistan.svg').default,
    china: require('./Assets/Images/china.svg').default,
    saudi_arabia: require('./Assets/Images/saudi-arabia.svg').default,
    bangladesh: require('./Assets/Images/bangladesh.svg').default,
    india: require('./Assets/Images/india.svg').default,
    tagalog: require('./Assets/Images/tagalog.svg').default,
    yt_icon: require('./Assets/Images/youtube_icon.svg').default,
    url_icon: require('./Assets/Images/url_icon.svg').default,
    url_image_1: require('./Assets/Images/url_images_1.jpeg'),
    url_image_2: require('./Assets/Images/url_images_2.jpeg'),
    url_image_3: require('./Assets/Images/url_images_3.jpeg'),

}

export const Languages = [
    {
        name: 'English',
        image: Images.english
    }, {
        name: 'اردو',
        image: Images.pakistan
    }, {
        name: 'عربى',
        image: Images.saudi_arabia
    }, {
        name: '中国人',
        image: Images.china
    }, {
        name: 'বাংলা',
        image: Images.bangladesh
    }, {
        name: 'हिन्दी',
        image: Images.india
    },
    {
        name: 'Tagalog',
        image: Images.tagalog
    },
]

export const ToolBox = {
    english: [
        {
            title: "January – Social Well-being",
            image_hd: Images.english10_hd,
            image: Images.english10_ld,
        }, {
            title: "February – Cancer Prevention",
            image_hd: Images.english11_hd,
            image: Images.english10_ld,
        }, {
            title: "March – Obesity Prevention",
            image_hd: Images.english12_hd,
            image: Images.english10_ld,
        }, {
            title: "April - Ramadan",
            image_hd: Images.english13_hd,
            image: Images.english10_ld,
        }, {
            title: "May - Fatigue",
            image_hd: Images.english14_hd,
            image: Images.english10_ld,
        }, {
            title: "June – Food Safety",
            image_hd: Images.english15_hd,
            image: Images.english10_ld,
        }, {
            title: "July- Heat Stress",
            image_hd: Images.english16_hd,
            image: Images.english10_ld,
        }, {
            title: "August- Heat Stress",
            image_hd: Images.english17_hd,
            image: Images.english10_ld,
        }, {
            title: "September- Suicide Prevention",
            image_hd: Images.english18_hd,
            image: Images.english10_ld,
        }, {
            title: "October- Mental health",
            image_hd: Images.english19_hd,
            image: Images.english10_ld,
        }, {
            title: "November- Nutrition",
            image_hd: Images.english20_hd,
            image: Images.english10_ld,
        },{
            title: "December – Winter Blues",
            image_hd: Images.english21_hd,
            image: Images.english10_ld,
        },
    ]
}
export const Blogs = {
    english: [
        {
            title: 'Recognizing Warning Signs',
            image_hd: Images.english1_hd,
            image: Images.english1_ld,
        }, {
            title: 'Managing Stress',
            image_hd: Images.english2_hd,
            image: Images.english2_ld,
        }, {
            title: 'Managing Grief',
            image_hd: Images.english3_hd,
            image: Images.english3_ld,
        }, {
            title: 'You are not Alone',
            image_hd: Images.english4_hd,
            image: Images.english4_ld,
        }, {
            title: 'You are not Alone',
            image_hd: Images.english5_hd,
            image: Images.english5_ld,
        }, {
            title: 'Feeling Down?',
            image_hd: Images.english6_hd,
            image: Images.english6_ld,
        }, {
            title: 'Contractor Well-being Porgram',
            image_hd: Images.english7_hd,
            image: Images.english7_ld,
        }, {
            title: "It's okay to ask for help",
            image_hd: Images.english8_hd,
            image: Images.english8_ld,
        }, {
            title: "Tips for maintaining mental health",
            image_hd: Images.english9_hd,
            image: Images.english9_ld,
        }, {
            title: "Benefits of Wellbeing",
            image_hd: Images.english22_hd,
            image: Images.english22_ld,
        }, {
            title: "Contractor Well-being Program",
            image_hd: Images.english23_hd,
            image: Images.english23_ld,
        }, {
            title: "Conflict Resolution Techniques",
            image_hd: Images.english24_hd,
            image: Images.english24_ld,
        }, {
            title: "Diabetes",
            image_hd: Images.english25_hd,
            image: Images.english25_ld,
        }, {
            title: "Building Social Support",
            image_hd: Images.english26_hd,
            image: Images.english26_ld,
        }, {
            title: "Growth mindset",
            image_hd: Images.english27_hd,
            image: Images.english27_ld,
        }, {
            title: "Positive Thinking",
            image_hd: Images.english28_hd,
            image: Images.english28_ld,
        }, {
            title: "PTSD",
            image_hd: Images.english29_hd,
            image: Images.english29_ld,
        }, {
            title: "Tips to help you sleep better",
            image_hd: Images.english30_hd,
            image: Images.english30_ld,
        }, {
            title: "Don't fight your battle alone",
            image_hd: Images.english31_hd,
            image: Images.english31_ld,
        }, {
            title: "Responding to stress",
            image_hd: Images.english32_hd,
            image: Images.english32_ld,
        }, {
            title: "How to create a safe space for mental health",
            image_hd: Images.english33_hd,
            image: Images.english33_ld,
        }, {
            title: "Diabetes Symptoms",
            image_hd: Images.english34_hd,
            image: Images.english34_ld,
        }, {
            title: "Breathing technique to reduce stress",
            image_hd: Images.english35_hd,
            image: Images.english35_ld,
        }, {
            title: "Achieve your best self",
            image_hd: Images.english36_hd,
            image: Images.english36_ld,
        }, {
            title: "Contractor well-being program",
            image_hd: Images.english37_hd,
            image: Images.english37_ld,
        }, {
            title: "Conflict resolution skills",
            image_hd: Images.english38_hd,
            image: Images.english38_ld,
        }, {
            title: "Know the symptoms",
            image_hd: Images.english39_hd,
            image: Images.english39_ld,
        }, {
            title: "What is fight or flight?",
            image_hd: Images.english40_hd,
            image: Images.english40_ld,
        }, {
            title: "How therapy helps",
            image_hd: Images.english41_hd,
            image: Images.english41_ld,
        }, {
            title: "Tips for maintaining mental health",
            image_hd: Images.english42_hd,
            image: Images.english42_ld,
        }, {
            title: "Sleep management",
            image_hd: Images.english43_hd,
            image: Images.english43_ld,
        },
        {
            title: "A problem shared is a problem halved",
            image_hd: Images.english44_hd,
            image: Images.english44_ld,
        },
        {
            title: "Ask a question, save a life",
            image_hd: Images.english45_hd,
            image: Images.english45_ld,
        },
        {
            title: "Broken heart",
            image_hd: Images.english46_hd,
            image: Images.english46_ld,
        },
        {
            title: "Common misconception",
            image_hd: Images.english47_hd,
            image: Images.english47_ld,
        },
        {
            title: "Contractor Passport Wallet Card to Management",
            image_hd: Images.english48_hd,
            image: Images.english48_ld,
        },
        {
            title: "Contractor-Passport Poster for Users",
            image_hd: Images.english49_hd,
            image: Images.english49_ld,
        },
        {
            title: "Contractor-Passport Wallet-Card for Users",
            image_hd: Images.english50_hd,
            image: Images.english50_ld,
        },
        {
            title: "Healthy Snacks",
            image_hd: Images.english51_hd,
            image: Images.english51_ld,
        },
        {
            title: "Management Contractor-Passport poster",
            image_hd: Images.english52_hd,
            image: Images.english52_ld,
        },
        {
            title: "Poster for WHO-5",
            image_hd: Images.english53_hd,
            image: Images.english53_ld,
        },
        {
            title: "QPR poster (with QR Codes)",
            image_hd: Images.english54_hd,
            image: Images.english54_ld,
        },
        {
            title: "Signs of Stress Aramco Poster",
            image_hd: Images.english55_hd,
            image: Images.english55_ld,
        },
        {
            title: "Tips to help prevent dehydration",
            image_hd: Images.english56_hd,
            image: Images.english56_ld,
        },
        {
            title: "Uplifting Proverb 1",
            image_hd: Images.english57_hd,
            image: Images.english57_ld,
        },
        {
            title: "Uplifting Proverb 3",
            image_hd: Images.english58_hd,
            image: Images.english58_ld,
        },
        {
            title: "When To Use Our Service",
            image_hd: Images.english59_hd,
            image: Images.english59_ld,
        },
        {
            title: "WHO-5 Sticker",
            image_hd: Images.english60_hd,
            image: Images.english60_ld,
        },        
    ],
    urdu: [
        {
            title: 'انتباہی نشانات کو پہچاننا',
            image_hd: Images.urdu1_hd,
            image: Images.urdu1_ld,
        }, {
            title: 'تناؤ / اسٹریس کا انتظام',
            image_hd: Images.urdu2_hd,
            image: Images.urdu2_ld,
        }, {
            title: 'غم کا انتظام',
            image_hd: Images.urdu3_hd,
            image: Images.urdu3_ld,
        }, {
            title: 'آپ تنہا نہیں ہیں',
            image_hd: Images.urdu4_hd,
            image: Images.urdu4_ld,
        }, {
            title: 'آپ تنہا نہیں ہیں',
            image_hd: Images.urdu5_hd,
            image: Images.urdu5_ld,
        }, {
            title: 'نیچا / مجبور محسوس کرنا؟',
            image_hd: Images.urdu6_hd,
            image: Images.urdu6_ld,
        }, {
            title: 'ورکرز کی فلاح و بہبود پروگرام',
            image_hd: Images.urdu7_hd,
            image: Images.urdu7_ld,
        }, {
            title: 'مدد مانگنا ٹھیک ہے',
            image_hd: Images.urdu8_hd,
            image: Images.urdu8_ld,
        }, {
            title: "دماغی صحت کو برقرار رکھنے کے لئے نکات",
            image_hd: Images.urdu9_hd,
            image: Images.urdu9_ld,
        }, {
            title: "نیند کی کمی کی وجہ سے کیاہو سکتا ہے؟",
            image_hd: Images.urdu10_hd,
            image: Images.urdu10_ld,
        }, {
            title: "سماجی تعاون کی کمی باعث بن سکتا ہے۔",
            image_hd: Images.urdu11_hd,
            image: Images.urdu11_ld,
        }, {
            title: "اپنی جنگ اکیلے مت لڑو",
            image_hd: Images.urdu12_hd,
            image: Images.urdu12_ld,
        }, {
            title: "خیر و عافیت کے فوائد",
            image_hd: Images.urdu13_hd,
            image: Images.urdu13_ld,
        }, {
            title: "تناؤ کا جواب دینا",
            image_hd: Images.urdu14_hd,
            image: Images.urdu14_ld,
        }, {
            title: "تنازعات کے حل کی تکنیک",
            image_hd: Images.urdu15_hd,
            image: Images.urdu15_ld,
        }, {
            title: "مثبت سوچ",
            image_hd: Images.urdu16_hd,
            image: Images.urdu16_ld,
        }, {
            title: "دماغی صحت کے لیے محفوظ جگہ کیسے بنائیں",
            image_hd: Images.urdu17_hd,
            image: Images.urdu17_ld,
        }, {
            title: "تناؤ کا جواب دینا",
            image_hd: Images.urdu18_hd,
            image: Images.urdu18_ld,
        }, {
            title: "تناؤ کو کم کرنے کے لیے سانس لینے کی تکنیک",
            image_hd: Images.urdu19_hd,
            image: Images.urdu19_ld,
        }, {
            title: "اپنے بہترین نفس کو حاصل کریں",
            image_hd: Images.urdu20_hd,
            image: Images.urdu20_ld,
        }, {
            title: "تنازعات کے حل کی مہارتیں",
            image_hd: Images.urdu21_hd,
            image: Images.urdu21_ld,
        }, {
            title: "ذیابیطس",
            image_hd: Images.urdu22_hd,
            image: Images.urdu22_ld,
        }, {
            title: "علامات کو جانیں",
            image_hd: Images.urdu23_hd,
            image: Images.urdu23_ld,
        }, {
            title: "لڑو یا بھاگو کیا ہے؟",
            image_hd: Images.urdu24_hd,
            image: Images.urdu24_ld,
        }, {
            title: "تھراپی کیسے مدد کرتی ہے؟",
            image_hd: Images.urdu25_hd,
            image: Images.urdu25_ld,
        }, {
            title: "نیند کا انتظام",
            image_hd: Images.urdu26_hd,
            image: Images.urdu26_ld,
        }, {
            title: "ورکرز کی فلاح و بہبود کا پروگرام",
            image_hd: Images.urdu27_hd,
            image: Images.urdu27_ld,
        }, {
            title: "فائدہ مند ترکیبیں دماغی صحت کے لیے",
            image_hd: Images.urdu28_hd,
            image: Images.urdu28_ld,
        }, {
            title: "PTSD کیا ہے؟",
            image_hd: Images.urdu29_hd,
            image: Images.urdu29_ld,
        }, {
            title: "کنٹریکٹر کی فالح و بہبود پروگرام",
            image_hd: Images.urdu30_hd,
            image: Images.urdu30_ld,
        }, {
            title: "ترقی کی سوچ",
            image_hd: Images.urdu31_hd,
            image: Images.urdu31_ld,
        }, {
            title: "ذیابیطس کی علامات",
            image_hd: Images.urdu32_hd,
            image: Images.urdu32_ld,
        },{
            title: "ایک مسئلہ شیئر کرنے سے مسئلہ آدھا ہو جاتا ہے",
            image_hd: Images.urdu33_hd,
            image: Images.urdu33_ld,
        },
        {
            title: "سوال پوچھیں، ایک زندگی بچائیں",
            image_hd: Images.urdu34_hd,
            image: Images.urdu34_ld,
        },
        {
            title: "ٹوٹا ہوا دل",
            image_hd: Images.urdu35_hd,
            image: Images.urdu35_ld,
        },
        {
            title: "عام غلط فہمی",
            image_hd: Images.urdu36_hd,
            image: Images.urdu36_ld,
        },
        {
            title: "کنٹریکٹر پاسپورٹ والیٹ کارڈ مینجمنٹ کے لیے",
            image_hd: Images.urdu37_hd,
            image: Images.urdu37_ld,
        },
        {
            title: "کنٹریکٹر-پاسپورٹ پوسٹر صارفین کے لیے",
            image_hd: Images.urdu38_hd,
            image: Images.urdu38_ld,
        },
        {
            title: "کنٹریکٹر-پاسپورٹ والیٹ-کارڈ صارفین کے لیے",
            image_hd: Images.urdu39_hd,
            image: Images.urdu39_ld,
        },
        {
            title: "صحت مند اسنیکس",
            image_hd: Images.urdu40_hd,
            image: Images.urdu40_ld,
        },
        {
            title: "مینجمنٹ کنٹریکٹر-پاسپورٹ پوسٹر",
            image_hd: Images.urdu41_hd,
            image: Images.urdu41_ld,
        },
        {
            title: "WHO-5 پوسٹر",
            image_hd: Images.urdu42_hd,
            image: Images.urdu42_ld,
        },
        {
            title: "QPR پوسٹر (QR کوڈز کے ساتھ)",
            image_hd: Images.urdu43_hd,
            image: Images.urdu43_ld,
        },
        {
            title: "اسٹریس کی علامات آرامکو پوسٹر",
            image_hd: Images.urdu44_hd,
            image: Images.urdu44_ld,
        },
        {
            title: "ڈی ہائیڈریشن سے بچاؤ کے لیے ٹپس",
            image_hd: Images.urdu45_hd,
            image: Images.urdu45_ld,
        },
        {
            title: "حوصلہ افزا قول ۱",
            image_hd: Images.urdu46_hd,
            image: Images.urdu46_ld,
        },
        {
            title: "حوصلہ افزا قول ۳",
            image_hd: Images.urdu47_hd,
            image: Images.urdu47_ld,
        },
        {
            title: "ہمارا سروس کب استعمال کریں",
            image_hd: Images.urdu48_hd,
            image: Images.urdu48_ld,
        },
        {
            title: "WHO-5 اسٹیکر",
            image_hd: Images.urdu49_hd,
            image: Images.urdu49_ld,
        },
        
    ],
    arabic: [
        {
            title: 'التعرف على علامات المنذرة',
            image_hd: Images.arabic1_hd,
            image: Images.arabic1_ld,
        }, {
            title: 'التعامل مع الضغوطات',
            image_hd: Images.arabic2_hd,
            image: Images.arabic2_ld,
        }, {
            title: 'التعامل مع الحزن',
            image_hd: Images.arabic3_hd,
            image: Images.arabic3_ld,
        }, {
            title: 'أنت لست وحدك',
            image_hd: Images.arabic4_hd,
            image: Images.arabic4_ld,
        }, {
            title: 'أنت لست وحدك',
            image_hd: Images.arabic5_hd,
            image: Images.arabic5_ld,
        }, {
            title: 'هل تشعر بالإ حباط؟',
            image_hd: Images.arabic6_hd,
            image: Images.arabic6_ld,
        }, {
            title: 'برنامج عافية المقاول',
            image_hd: Images.arabic7_hd,
            image: Images.arabic7_ld,
        }, {
            title: ' لیس علیك خوض معرکتك لوحدك',
            image_hd: Images.arabic8_hd,
            image: Images.arabic8_ld,
        }, {
            title: "نصائح للمحافظة على الصحة النفسية",
            image_hd: Images.arabic9_hd,
            image: Images.arabic9_ld,
        }, {
            title: " قلة النوم ممکن ان تسبب",
            image_hd: Images.arabic10_hd,
            image: Images.arabic10_ld,
        }, {
            title: "يمكن أن يؤدي نقص الدعم الاجتماعي إلی",
            image_hd: Images.arabic11_hd,
            image: Images.arabic11_ld,
        }, {
            title: "لا تقاتل معركتك وحدك",
            image_hd: Images.arabic12_hd,
            image: Images.arabic12_ld,
        }, {
            title: "فوائد العافية",
            image_hd: Images.arabic13_hd,
            image: Images.arabic13_ld,
        }, {
            title: "التعامل مع التواتر النفسی",
            image_hd: Images.arabic14_hd,
            image: Images.arabic14_ld,
        }, {
            title: "اضطراب ما بعد الصدمة",
            image_hd: Images.arabic15_hd,
            image: Images.arabic15_ld,
        }, {
            title: "التفکیر الایجابی",
            image_hd: Images.arabic16_hd,
            image: Images.arabic16_ld,
        }, {
            title: "برنامج العافیة للمتعاقدین",
            image_hd: Images.arabic17_hd,
            image: Images.arabic17_ld,
        }, {
            title: "تقنیات حل النزاع",
            image_hd: Images.arabic18_hd,
            image: Images.arabic18_ld,
        }, {
            title: "مرض السکری",
            image_hd: Images.arabic19_hd,
            image: Images.arabic19_ld,
        }, {
            title: "عقلیة النمو",
            image_hd: Images.arabic20_hd,
            image: Images.arabic20_ld,
        },{
            title: "كيف بإمكانك تهيئة مساحة أمنة للتحدث عن الصحة النفسية",
            image_hd: Images.arabic21_hd,
            image: Images.arabic21_ld,
        }, {
            title: "أعراض مرض السكري",
            image_hd: Images.arabic22_hd,
            image: Images.arabic22_ld,
        }, {
            title: "نصائح للمحافظة على الصحة النفسية",
            image_hd: Images.arabic23_hd,
            image: Images.arabic23_ld,
        }, {
            title: "أساليب تقليل التوتر عبر التنفس العميق",
            image_hd: Images.arabic24_hd,
            image: Images.arabic24_ld,
        }, {
            title: "يمكنك أن تكون في أفضل حال",
            image_hd: Images.arabic25_hd,
            image: Images.arabic25_ld,
        }, {
            title: "مهارات حل النزاع",
            image_hd: Images.arabic26_hd,
            image: Images.arabic26_ld,
        }, {
            title: "برنامج عافية المقاول",
            image_hd: Images.arabic27_hd,
            image: Images.arabic27_ld,
        }, {
            title: "تعرف على الأعراض",
            image_hd: Images.arabic28_hd,
            image: Images.arabic28_ld,
        }, {
            title: "ما هى استجابة الكر أو الفر؟",
            image_hd: Images.arabic29_hd,
            image: Images.arabic29_ld,
        }, {
            title: "كيف سيساعدك العلاج النفسي",
            image_hd: Images.arabic30_hd,
            image: Images.arabic30_ld,
        }, {
            title: "تنظیم النوم",
            image_hd: Images.arabic31_hd,
            image: Images.arabic31_ld,
        }, {
            title: "المشكلة التي تُشارك تُصبح نصف مشكلة",
            image_hd: Images.arabic32_hd,
            image: Images.arabic32_ld,
        }, {
            title: "اسأل سؤالاً، تنقذ حياة",
            image_hd: Images.arabic33_hd,
            image: Images.arabic33_ld,
        }, {
            title: "قلب مكسور",
            image_hd: Images.arabic34_hd,
            image: Images.arabic34_ld,
        }, {
            title: "مفهوم خاطئ شائع",
            image_hd: Images.arabic35_hd,
            image: Images.arabic35_ld,
        }, {
            title: "بطاقة جواز السفر للمقاول إلى الإدارة",
            image_hd: Images.arabic36_hd,
            image: Images.arabic36_ld,
        }, {
            title: "ملصق جواز السفر للمقاول للمستخدمين",
            image_hd: Images.arabic37_hd,
            image: Images.arabic37_ld,
        }, {
            title: "بطاقة جواز السفر للمقاول للمستخدمين",
            image_hd: Images.arabic38_hd,
            image: Images.arabic38_ld,
        }, {
            title: "وجبات خفيفة صحية",
            image_hd: Images.arabic39_hd,
            image: Images.arabic39_ld,
        }, {
            title: "ملصق جواز سفر المقاول للإدارة",
            image_hd: Images.arabic40_hd,
            image: Images.arabic40_ld,
        }, {
            title: "ملصق لمنظمة الصحة العالمية-5",
            image_hd: Images.arabic41_hd,
            image: Images.arabic41_ld,
        }, {
            title: "ملصق QPR (مع رموز QR)",
            image_hd: Images.arabic42_hd,
            image: Images.arabic42_ld,
        }, {
            title: "علامات التوتر أرامكو",
            image_hd: Images.arabic43_hd,
            image: Images.arabic43_ld,
        }, {
            title: "نصائح للمساعدة في منع الجفاف",
            image_hd: Images.arabic44_hd,
            image: Images.arabic44_ld,
        }, {
            title: "مثل مرفّع 1",
            image_hd: Images.arabic45_hd,
            image: Images.arabic45_ld,
        }, {
            title: "مثل مرفّع 3",
            image_hd: Images.arabic46_hd,
            image: Images.arabic46_ld,
        }, {
            title: "متى يجب استخدام خدمتنا",
            image_hd: Images.arabic47_hd,
            image: Images.arabic47_ld,
        }, {
            title: "ملصق WHO-5",
            image_hd: Images.arabic48_hd,
            image: Images.arabic48_ld,
        },
    ],
    bangali: [
        {
            title: 'সতর্কতা চিহ্ন সনাক্তকরণ',
            image_hd: Images.bangali1_hd,
            image: Images.bangali1_ld,
        }, {
            title: 'স্ট্রেস ব্যবস্থাপনা',
            image_hd: Images.bangali2_hd,
            image: Images.bangali2_ld,
        }, {
            title: 'দুঃখ ব্যবস্থাপনা',
            image_hd: Images.bangali3_hd,
            image: Images.bangali3_ld,
        }, {
            title: 'তুমি একা নও',
            image_hd: Images.bangali4_hd,
            image: Images.bangali4_ld,
        }, {
            title: 'তুমি একা নও',
            image_hd: Images.bangali5_hd,
            image: Images.bangali5_ld,
        }, {
            title: 'নিচের অনুভূতি?',
            image_hd: Images.bangali6_hd,
            image: Images.bangali6_ld,
        }, {
            title: ' ঠিকাদার কল্যাণ প্রোগ্রাম',
            image_hd: Images.bangali7_hd,
            image: Images.bangali7_ld,
        }, {
            title: 'সাহায্যের জন্য জিজ্ঞাসা করা ঠিক আছে',
            image_hd: Images.bangali8_hd,
            image: Images.bangali8_ld,
        }, {
            title: "মানসিক স্বাস্থ্য বজায় রাখার জন্য টিপস",
            image_hd: Images.bangali9_hd,
            image: Images.bangali9_ld,
        }, {
            title: "ঘুমের অভাবের কারণ কী হতে পারে?",
            image_hd: Images.bangali10_hd,
            image: Images.bangali10_ld,
        }, {
            title: "সামাজিক সমর্থনের অভাব হতে পারে।",
            image_hd: Images.bangali11_hd,
            image: Images.bangali11_ld,
        }, {
            title: "একা একা যুদ্ধ করবেন না",
            image_hd: Images.bangali12_hd,
            image: Images.bangali12_ld,
        }, {
            title: "সুস্থতার সুবিধা",
            image_hd: Images.bangali13_hd,
            image: Images.bangali13_ld,
        }, {
            title: "স্ট্রেস এর প্রতিক্রিয়া",
            image_hd: Images.bangali14_hd,
            image: Images.bangali14_ld,
        }, {
            title: "উদ্বেগ মানসিকতা",
            image_hd: Images.bangali15_hd,
            image: Images.bangali15_ld,
        }, {
            title: "ডায়াবেটিস",
            image_hd: Images.bangali16_hd,
            image: Images.bangali16_ld,
        }, {
            title: "কম্বাইন্ড কল্যাণ কর্মসূচি",
            image_hd: Images.bangali17_hd,
            image: Images.bangali17_ld,
        }, {
            title: "4 ধরনের PTSD উপসর্গ:",
            image_hd: Images.bangali18_hd,
            image: Images.bangali18_ld,
        }, {
            title: "ইতিবাচক চিন্তা",
            image_hd: Images.bangali19_hd,
            image: Images.bangali19_ld,
        }, {
            title: "দ্রুত সমাধানের কৌশল",
            image_hd: Images.bangali20_hd,
            image: Images.bangali20_ld,
        }, {
            title: "স্ট্রেসের প্রতিক্রিয়া",
            image_hd: Images.bangali21_hd,
            image: Images.bangali21_ld,
        }, {
            title: "সুস্থতা কর্মসূচীর সংগঠন",
            image_hd: Images.bangali22_hd,
            image: Images.bangali22_ld,
        }, {
            title: "ডায়াবেটিস লক্ষণ",
            image_hd: Images.bangali23_hd,
            image: Images.bangali23_ld,
        }, {
            title: "বজায় রাখার জন্য পরামর্শ মানসিক স্বাস্থ্য",
            image_hd: Images.bangali24_hd,
            image: Images.bangali24_ld,
        }, {
            title: "স্ট্রেস কমাতে শ্বাস প্রশ্বাসের কৌশল",
            image_hd: Images.bangali25_hd,
            image: Images.bangali25_ld,
        }, {
            title: "নিজের মধ্যে সেরা অর্জনের কথা ভাবুন",
            image_hd: Images.bangali26_hd,
            image: Images.bangali26_ld,
        }, {
            title: "দ্বন্দ্ব সমাধানের দক্ষতা",
            image_hd: Images.bangali27_hd,
            image: Images.bangali27_ld,
        }, {
            title: "লক্ষণগুলি জানুন",
            image_hd: Images.bangali28_hd,
            image: Images.bangali28_ld,
        }, {
            title: "ফাইট অর ফ্লাইট কী?",
            image_hd: Images.bangali29_hd,
            image: Images.bangali29_ld,
        }, {
            title: "কিভাবে থেরাপি সাহায্য করে",
            image_hd: Images.bangali30_hd,
            image: Images.bangali30_ld,
        }, {
            title: "ঘুম ব্যবস্থাপনা",
            image_hd: Images.bangali31_hd,
            image: Images.bangali31_ld,
        }, {
            title: "দলগুলোর সুস্থতা কীভাবে সহায়তা করবেন",
            image_hd: Images.bangali32_hd,
            image: Images.bangali32_ld,
        },{
            title: "একটি সমস্যা ভাগ করলে সমস্যা অর্ধেক হয়ে যায়",
            image_hd: Images.bangali33_hd,
            image: Images.bangali33_ld,
        },
        {
            title: "প্রশ্ন করুন, একটি জীবন বাঁচান",
            image_hd: Images.bangali34_hd,
            image: Images.bangali34_ld,
        },
        {
            title: "ভাঙ্গা হৃদয়",
            image_hd: Images.bangali35_hd,
            image: Images.bangali35_ld,
        },
        {
            title: "সাধারণ ভুল ধারণা",
            image_hd: Images.bangali36_hd,
            image: Images.bangali36_ld,
        },
        {
            title: "কন্ট্রাক্টর পাসপোর্ট ওয়ালেট কার্ড ম্যানেজমেন্টের জন্য",
            image_hd: Images.bangali37_hd,
            image: Images.bangali37_ld,
        },
        {
            title: "কন্ট্রাক্টর-পাসপোর্ট পোস্টার ব্যবহারকারীদের জন্য",
            image_hd: Images.bangali38_hd,
            image: Images.bangali38_ld,
        },
        {
            title: "কন্ট্রাক্টর-পাসপোর্ট ওয়ালেট-কার্ড ব্যবহারকারীদের জন্য",
            image_hd: Images.bangali39_hd,
            image: Images.bangali39_ld,
        },
        {
            title: "স্বাস্থ্যকর স্ন্যাকস",
            image_hd: Images.bangali40_hd,
            image: Images.bangali40_ld,
        },
        {
            title: "ম্যানেজমেন্ট কন্ট্রাক্টর-পাসপোর্ট পোস্টার",
            image_hd: Images.bangali41_hd,
            image: Images.bangali41_ld,
        },
        {
            title: "WHO-5 পোস্টার",
            image_hd: Images.bangali42_hd,
            image: Images.bangali42_ld,
        },
        {
            title: "QPR পোস্টার (QR কোড সহ)",
            image_hd: Images.bangali43_hd,
            image: Images.bangali43_ld,
        },
        {
            title: "স্ট্রেসের লক্ষণ আরামকো পোস্টার",
            image_hd: Images.bangali44_hd,
            image: Images.bangali44_ld,
        },
        {
            title: "ডিহাইড্রেশন প্রতিরোধে সহায়ক টিপস",
            image_hd: Images.bangali45_hd,
            image: Images.bangali45_ld,
        },
        {
            title: "উত্সাহজনক প্রবাদ ১",
            image_hd: Images.bangali46_hd,
            image: Images.bangali46_ld,
        },
        {
            title: "উত্সাহজনক প্রবাদ ৩",
            image_hd: Images.bangali47_hd,
            image: Images.bangali47_ld,
        },
        {
            title: "আমাদের সেবা কখন ব্যবহার করবেন",
            image_hd: Images.bangali48_hd,
            image: Images.bangali48_ld,
        },
        {
            title: "WHO-5 স্টিকার",
            image_hd: Images.bangali49_hd,
            image: Images.bangali49_ld,
        },        
    ],
    chinese: [
        {
            title: '识别警告标志',
            image_hd: Images.chinese1_hd,
            image: Images.chinese1_ld,
        }, {
            title: '管理压力',
            image_hd: Images.chinese2_hd,
            image: Images.chinese2_ld,
        }, {
            title: '管理悲伤',
            image_hd: Images.chinese3_hd,
            image: Images.chinese3_ld,
        }, {
            title: '你并不孤单',
            image_hd: Images.chinese4_hd,
            image: Images.chinese4_ld,
        }, {
            title: '你并不孤单',
            image_hd: Images.chinese5_hd,
            image: Images.chinese5_ld,
        }, {
            title: '情绪低落？',
            image_hd: Images.chinese6_hd,
            image: Images.chinese6_ld,
        }, {
            title: '积极的情绪',
            image_hd: Images.chinese_positive_emotions_1_hd,
            image: Images.chinese_positive_emotions_1_ld,
        }, {
            title: '积极的情绪',
            image_hd: Images.chinese_positive_emotions_2_hd,
            image: Images.chinese_positive_emotions_2_ld,
        }, {
            title: '承包商福利计划',
            image_hd: Images.chinese7_hd,
            image: Images.chinese7_ld,
        }, {
            title: '可以寻求帮助',
            image_hd: Images.chinese8_hd,
            image: Images.chinese8_ld,
        }, {
            title: "保持心理健康的提示",
            image_hd: Images.chinese9_hd,
            image: Images.chinese9_ld,
        }, {
            title: "睡眠不足的原因可能是什么？",
            image_hd: Images.chinese10_hd,
            image: Images.chinese10_ld,
        }, {
            title: "缺乏社会支持会导致。",
            image_hd: Images.chinese11_hd,
            image: Images.chinese11_ld,
        }, {
            title: "不要孤军奋战",
            image_hd: Images.chinese12_hd,
            image: Images.chinese12_ld,
        }, {
            title: "福祉的好处",
            image_hd: Images.chinese13_hd,
            image: Images.chinese13_ld,
        }, {
            title: "承包商福利计划",
            image_hd: Images.chinese14_hd,
            image: Images.chinese14_ld,
        }, {
            title: "解决冲突的技巧",
            image_hd: Images.chinese15_hd,
            image: Images.chinese15_ld,
        }, {
            title: "糖尿病",
            image_hd: Images.chinese16_hd,
            image: Images.chinese16_ld,
        }, {
            title: "积极的思考",
            image_hd: Images.chinese17_hd,
            image: Images.chinese17_ld,
        }, {
            title: "成长型思维",
            image_hd: Images.chinese18_hd,
            image: Images.chinese18_ld,
        }, {
            title: "创伤后应激障碍的4种症状",
            image_hd: Images.chinese19_hd,
            image: Images.chinese19_ld,
        }, {
            title: "应对压力",
            image_hd: Images.chinese20_hd,
            image: Images.chinese20_ld,
        }, {
            title: "创建心理健康的安全空间",
            image_hd: Images.chinese21_hd,
            image: Images.chinese21_ld
        }, {
            title: "缓解压力的呼吸技巧",
            image_hd: Images.chinese22_hd,
            image: Images.chinese22_ld
        }, {
            title: "做最好的自己",
            image_hd: Images.chinese23_hd,
            image: Images.chinese23_ld
        }, {
            title: "解决冲突的技巧",
            image_hd: Images.chinese24_hd,
            image: Images.chinese24_ld
        }, {
            title: "承包商福利计划",
            image_hd: Images.chinese25_hd,
            image: Images.chinese25_ld
        }, {
            title: "糖尿病 症状",
            image_hd: Images.chinese26_hd,
            image: Images.chinese26_ld
        }, {
            title: "了解症状",
            image_hd: Images.chinese27_hd,
            image: Images.chinese27_ld
        }, {
            title: "什么是‘战斗或逃跑’反应?",
            image_hd: Images.chinese28_hd,
            image: Images.chinese28_ld
        }, {
            title: "治疗如何帮助",
            image_hd: Images.chinese29_hd,
            image: Images.chinese29_ld
        }, {
            title: "保持心理健康的小贴士",
            image_hd: Images.chinese30_hd,
            image: Images.chinese30_ld
        }, {
            title: "睡眠管理",
            image_hd: Images.chinese31_hd,
            image: Images.chinese31_ld
        }, {
            title: "问题共享，问题减半",
            image_hd: Images.chinese32_hd,
            image: Images.chinese32_ld
        },
        {
            title: "提出问题，拯救生命",
            image_hd: Images.chinese33_hd,
            image: Images.chinese33_ld
        },
        {
            title: "伤心",
            image_hd: Images.chinese34_hd,
            image: Images.chinese34_ld
        },
        {
            title: "常见误解",
            image_hd: Images.chinese35_hd,
            image: Images.chinese35_ld
        },
        {
            title: "承包商护照钱包卡到管理层",
            image_hd: Images.chinese36_hd,
            image: Images.chinese36_ld
        },
        {
            title: "承包商护照用户海报",
            image_hd: Images.chinese37_hd,
            image: Images.chinese37_ld
        },
        {
            title: "承包商护照钱包卡用户版",
            image_hd: Images.chinese38_hd,
            image: Images.chinese38_ld
        },
        {
            title: "健康小吃",
            image_hd: Images.chinese39_hd,
            image: Images.chinese39_ld
        },
        {
            title: "管理层承包商护照海报",
            image_hd: Images.chinese40_hd,
            image: Images.chinese40_ld
        },
        {
            title: "WHO-5海报",
            image_hd: Images.chinese41_hd,
            image: Images.chinese41_ld
        },
        {
            title: "带有二维码的QPR海报",
            image_hd: Images.chinese42_hd,
            image: Images.chinese42_ld
        },
        {
            title: "阿美石油公司压力迹象海报",
            image_hd: Images.chinese43_hd,
            image: Images.chinese43_ld
        },
        {
            title: "预防脱水的提示",
            image_hd: Images.chinese44_hd,
            image: Images.chinese44_ld
        },
        {
            title: "鼓舞人心的格言1",
            image_hd: Images.chinese45_hd,
            image: Images.chinese45_ld
        },
        {
            title: "鼓舞人心的格言3",
            image_hd: Images.chinese46_hd,
            image: Images.chinese46_ld
        },
        {
            title: "何时使用我们的服务",
            image_hd: Images.chinese47_hd,
            image: Images.chinese47_ld
        },
        {
            title: "WHO-5贴纸",
            image_hd: Images.chinese48_hd,
            image: Images.chinese48_ld
        }        
    ],
    hindi: [
        {
            title: 'चेतावनी के संकेतों को पहचानना',
            image_hd: Images.hindi1_hd,
            image: Images.hindi1_ld,
        }, {
            title: 'प्रबंधन तनाव',
            image_hd: Images.hindi2_hd,
            image: Images.hindi2_ld,
        }, {
            title: 'दुख का प्रबंधन',
            image_hd: Images.hindi3_hd,
            image: Images.hindi3_ld,
        }, {
            title: 'तुम अकेले नही हो',
            image_hd: Images.hindi4_hd,
            image: Images.hindi4_ld,
        }, {
            title: 'तुम अकेले नही हो',
            image_hd: Images.hindi5_hd,
            image: Images.hindi5_ld,
        }, {
            title: 'बुरा महसूस करना?',
            image_hd: Images.hindi6_hd,
            image: Images.hindi6_ld,
        }, {
            title: 'ठेकेदार कल्याण कार्यक्रम',
            image_hd: Images.hindi7_hd,
            image: Images.hindi7_ld,
        }, {
            title: 'मदद मांगना ठीक है',
            image_hd: Images.hindi8_hd,
            image: Images.hindi8_ld,
        }, {
            title: "मानसिक स्वास्थ्य को बनाए रखने के लिए टिप्स",
            image_hd: Images.hindi9_hd,
            image: Images.hindi9_ld,
        }, {
            title: "नींद पूरी न होने का क्या कारण हो सकता है?",
            image_hd: Images.hindi10_hd,
            image: Images.hindi10_ld,
        }, {
            title: "सामाजिक समर्थन की कमी हो सकती है।",
            image_hd: Images.hindi11_hd,
            image: Images.hindi11_ld,
        }, {
            title: "अपनी लड़ाई अकेले मत लड़ो",
            image_hd: Images.hindi12_hd,
            image: Images.hindi12_ld,
        }, {
            title: "भलाई के लाभ",
            image_hd: Images.hindi13_hd,
            image: Images.hindi13_ld,
        }, {
            title: "पीटीएसडी के 4 प्रकार के लक्षण",
            image_hd: Images.hindi14_hd,
            image: Images.hindi14_ld,
        }, {
            title: "ठेकेदार कल्याण कार्यक्रम",
            image_hd: Images.hindi15_hd,
            image: Images.hindi15_ld,
        }, {
            title: "मधुमेह",
            image_hd: Images.hindi16_hd,
            image: Images.hindi16_ld,
        }, {
            title: "मानसिकता का विकास",
            image_hd: Images.hindi17_hd,
            image: Images.hindi17_ld,
        }, {
            title: "संघर्ष समाधान के लिए तकनीक",
            image_hd: Images.hindi18_hd,
            image: Images.hindi18_ld,
        }, {
            title: "सकारात्मक सोच",
            image_hd: Images.hindi19_hd,
            image: Images.hindi19_ld,
        }, {
            title: "तनाव की प्रतिक्रिया",
            image_hd: Images.hindi20_hd,
            image: Images.hindi20_ld,
        }, {
            title: "तनाव का जवाब देना",
            image_hd: Images.hindi21_hd,
            image: Images.hindi21_ld,
        }, {
            title: "तनाव कम करने के लिए साँस लेने की तकनीक",
            image_hd: Images.hindi22_hd,
            image: Images.hindi22_ld,
        }, {
            title: "आपका अपना सर्वश्रेष्ठ स्व प्राप्त करें",
            image_hd: Images.hindi23_hd,
            image: Images.hindi23_ld,
        }, {
            title: "श्रमकि खुशी कार्यक्रम",
            image_hd: Images.hindi24_hd,
            image: Images.hindi24_ld,
        }, {
            title: "संघर्ष समाधान के कौशल",
            image_hd: Images.hindi25_hd,
            image: Images.hindi25_ld,
        }, {
            title: "मध् लक्षण",
            image_hd: Images.hindi26_hd,
            image: Images.hindi26_ld,
        }, {
            title: "लक्षण जानें",
            image_hd: Images.hindi27_hd,
            image: Images.hindi27_ld,
        }, {
            title: "फाइट या फ्लाइट क्या है?",
            image_hd: Images.hindi28_hd,
            image: Images.hindi28_ld,
        }, {
            title: "मानसिक स्वास्थ्य",
            image_hd: Images.hindi29_hd,
            image: Images.hindi29_ld,
        }, {
            title: "थेरेपी कैसे मदद करती है",
            image_hd: Images.hindi30_hd,
            image: Images.hindi30_ld,
        }, {
            title: "नींद प्रबंधन",
            image_hd: Images.hindi31_hd,
            image: Images.hindi31_ld,
        }, {
            title: "टीमों की भलाई में कैसे मदद करें",
            image_hd: Images.hindi32_hd,
            image: Images.hindi32_ld,
        },{
            title: "एक समस्या साझा करने से समस्या आधी हो जाती है",
            image_hd: Images.hindi33_hd,
            image: Images.hindi33_ld,
        },
        {
            title: "सवाल पूछें, एक जीवन बचाएं",
            image_hd: Images.hindi34_hd,
            image: Images.hindi34_ld,
        },
        {
            title: "टूटा हुआ दिल",
            image_hd: Images.hindi35_hd,
            image: Images.hindi35_ld,
        },
        {
            title: "आम भ्रांति",
            image_hd: Images.hindi36_hd,
            image: Images.hindi36_ld,
        },
        {
            title: "ठेकेदार पासपोर्ट वॉलेट कार्ड प्रबंधन के लिए",
            image_hd: Images.hindi37_hd,
            image: Images.hindi37_ld,
        },
        {
            title: "ठेकेदार-पासपोर्ट पोस्टर उपयोगकर्ताओं के लिए",
            image_hd: Images.hindi38_hd,
            image: Images.hindi38_ld,
        },
        {
            title: "ठेकेदार-पासपोर्ट वॉलेट-कार्ड उपयोगकर्ताओं के लिए",
            image_hd: Images.hindi39_hd,
            image: Images.hindi39_ld,
        },
        {
            title: "स्वस्थ स्नैक्स",
            image_hd: Images.hindi40_hd,
            image: Images.hindi40_ld,
        },
        {
            title: "प्रबंधन ठेकेदार-पासपोर्ट पोस्टर",
            image_hd: Images.hindi41_hd,
            image: Images.hindi41_ld,
        },
        {
            title: "WHO-5 पोस्टर",
            image_hd: Images.hindi42_hd,
            image: Images.hindi42_ld,
        },
        {
            title: "QPR पोस्टर (QR कोड के साथ)",
            image_hd: Images.hindi43_hd,
            image: Images.hindi43_ld,
        },
        {
            title: "तनाव के संकेत अरामको पोस्टर",
            image_hd: Images.hindi44_hd,
            image: Images.hindi44_ld,
        },
        {
            title: "पानी की कमी से बचाव के लिए टिप्स",
            image_hd: Images.hindi45_hd,
            image: Images.hindi45_ld,
        },
        {
            title: "प्रेरणादायक कहावत 1",
            image_hd: Images.hindi46_hd,
            image: Images.hindi46_ld,
        },
        {
            title: "प्रेरणादायक कहावत 3",
            image_hd: Images.hindi47_hd,
            image: Images.hindi47_ld,
        },
        {
            title: "हमारी सेवा का उपयोग कब करें",
            image_hd: Images.hindi48_hd,
            image: Images.hindi48_ld,
        },
        {
            title: "WHO-5 स्टिकर",
            image_hd: Images.hindi49_hd,
            image: Images.hindi49_ld,
        },        
    ],
    tagalog: [
        {
            title: 'Kilalanin ang mga Babalang Palatandaan',
            image_hd: Images.tagalog1_hd,
            image: Images.tagalog1_ld,
        }, {
            title: 'Pamamahala ng stress',
            image_hd: Images.tagalog2_hd,
            image: Images.tagalog2_ld,
        }, {
            title: 'Pamamahala ng kalungkutan',
            image_hd: Images.tagalog3_hd,
            image: Images.tagalog3_ld,
        }, {
            title: 'HINDI KA NAGIISA',
            image_hd: Images.tagalog4_hd,
            image: Images.tagalog4_ld,
        }, {
            title: 'HINDI KA NAGIISA',
            image_hd: Images.tagalog5_hd,
            image: Images.tagalog5_ld,
        }, {
            title: 'Malungkot?',
            image_hd: Images.tagalog6_hd,
            image: Images.tagalog6_ld,
        }, {
            title: 'Programang Kagalingan ng mga Manggagawa',
            image_hd: Images.tagalog7_hd,
            image: Images.tagalog7_ld,
        }, {
            title: 'Okay lang humingi ng tulong',
            image_hd: Images.tagalog8_hd,
            image: Images.tagalog8_ld,
        }, {
            title: "Mga tip para sa pagpapanatili ng kalusugan ng isip",
            image_hd: Images.tagalog9_hd,
            image: Images.tagalog9_ld,
        }, {
            title: "Ano ang maaaring maging sanhi ng kakulangan sa tulog?",
            image_hd: Images.tagalog10_hd,
            image: Images.tagalog10_ld,
        }, {
            title: "Maaaring humantong ang kakulangan sa suportang panlipunan",
            image_hd: Images.tagalog11_hd,
            image: Images.tagalog11_ld,
        }, {
            title: "Huwag mong ipaglaban ang iyong laban nang mag-isa",
            image_hd: Images.tagalog12_hd,
            image: Images.tagalog12_ld,
        }, {
            title: "Ang mga benepisyo ng kagalingan",
            image_hd: Images.tagalog13_hd,
            image: Images.tagalog13_ld,
        }, {
            title: "APAT NA URI NG SINTOMAS NG PTSD",
            image_hd: Images.tagalog14_hd,
            image: Images.tagalog14_ld,
        }, {
            title: "Programa para sa Kapakanan ng mga Kontraktor",
            image_hd: Images.tagalog15_hd,
            image: Images.tagalog15_ld,
        }, {
            title: "Diabetes",
            image_hd: Images.tagalog16_hd,
            image: Images.tagalog16_ld,
        }, {
            title: "Malagong kaisipan",
            image_hd: Images.tagalog17_hd,
            image: Images.tagalog17_ld,
        }, {
            title: "Mga pamamaraan sa paglutas ng salungatan",
            image_hd: Images.tagalog18_hd,
            image: Images.tagalog18_ld,
        }, {
            title: "Positibong pag-iisip",
            image_hd: Images.tagalog19_hd,
            image: Images.tagalog19_ld,
        }, {
            title: "Pagtugon sa stress",
            image_hd: Images.tagalog20_hd,
            image: Images.tagalog20_ld,
        }, {
            title: "Pagtugon sa stress",
            image_hd: Images.tagalog21_hd,
            image: Images.tagalog21_ld,
        }, {
            title: "Ano ang 'Labon o Takbo'?",
            image_hd: Images.tagalog22_hd,
            image: Images.tagalog22_ld,
        }, {
            title: "Paano makakatulong ang therapy o gamutan",
            image_hd: Images.tagalog23_hd,
            image: Images.tagalog23_ld,
        }, {
            title: "MGA KASANAYAN SA PAGLUTAS NG SALUNGATAN",
            image_hd: Images.tagalog24_hd,
            image: Images.tagalog24_ld,
        }, {
            title: "PAMAMAHALA NG PAGTULOG",
            image_hd: Images.tagalog25_hd,
            image: Images.tagalog25_ld,
        }, {
            title: "MGA TIP PARA SA PAGPAPANATILI NG KALUSUGAN NG ISIP",
            image_hd: Images.tagalog26_hd,
            image: Images.tagalog26_ld,
        }, {
            title: "Paghinga gamit ang tiyan",
            image_hd: Images.tagalog27_hd,
            image: Images.tagalog27_ld,
        }, {
            title: "Panawagan sa Aksyon",
            image_hd: Images.tagalog28_hd,
            image: Images.tagalog28_ld,
        }, {
            title: "Karamdaman",
            image_hd: Images.tagalog29_hd,
            image: Images.tagalog29_ld,
        }, {
            title: "Kilalanin ang mga Sintomas",
            image_hd: Images.tagalog30_hd,
            image: Images.tagalog30_ld,
        },{
            title: "Ang isang problema na ibinabahagi ay problema na kalahati na",
            image_hd: Images.tagalog31_hd,
            image: Images.tagalog31_ld,
        },
        {
            title: "Magtanong ka, magligtas ng buhay",
            image_hd: Images.tagalog32_hd,
            image: Images.tagalog32_ld,
        },
        {
            title: "Wasak na puso",
            image_hd: Images.tagalog33_hd,
            image: Images.tagalog33_ld,
        },
        {
            title: "Karaniwang maling pag-unawa",
            image_hd: Images.tagalog34_hd,
            image: Images.tagalog34_ld,
        },
        {
            title: "Contractor Passport Wallet Card para sa Pamamahala",
            image_hd: Images.tagalog35_hd,
            image: Images.tagalog35_ld,
        },
        {
            title: "Contractor-Passport Poster para sa mga Gumagamit",
            image_hd: Images.tagalog36_hd,
            image: Images.tagalog36_ld,
        },
        {
            title: "Contractor-Passport Wallet-Card para sa mga Gumagamit",
            image_hd: Images.tagalog37_hd,
            image: Images.tagalog37_ld,
        },
        {
            title: "Malusog na Meryenda",
            image_hd: Images.tagalog38_hd,
            image: Images.tagalog38_ld,
        },
        {
            title: "Pamamahala Contractor-Passport Poster",
            image_hd: Images.tagalog39_hd,
            image: Images.tagalog39_ld,
        },
        {
            title: "Poster para sa WHO-5",
            image_hd: Images.tagalog40_hd,
            image: Images.tagalog40_ld,
        },
        {
            title: "QPR Poster (na may QR Codes)",
            image_hd: Images.tagalog41_hd,
            image: Images.tagalog41_ld,
        },
        {
            title: "Mga Palatandaan ng Stress Aramco Poster",
            image_hd: Images.tagalog42_hd,
            image: Images.tagalog42_ld,
        },
        {
            title: "Mga Tip upang Maiwasan ang Pagka-dehydrate",
            image_hd: Images.tagalog43_hd,
            image: Images.tagalog43_ld,
        },
        {
            title: "Nakakaangat na Kasabihan 1",
            image_hd: Images.tagalog44_hd,
            image: Images.tagalog44_ld,
        },
        {
            title: "Nakakaangat na Kasabihan 3",
            image_hd: Images.tagalog45_hd,
            image: Images.tagalog45_ld,
        },
        {
            title: "Kailan Gamitin ang Aming Serbisyo",
            image_hd: Images.tagalog46_hd,
            image: Images.tagalog46_ld,
        },
        {
            title: "WHO-5 Sticker",
            image_hd: Images.tagalog47_hd,
            image: Images.tagalog47_ld,
        },{
            title: "Paano Tumulong sa Kapakanan ng mga Koponan",
            image_hd: Images.tagalog48_hd,
            image: Images.tagalog48_ld,
        },
        
    ],
}

export const YTlinks = {
    english: [{
        title: 'What is Mental Health?',
        embedId: 'G0zJGDokyWQ'
    },
    {
        title: 'Mental Health Awareness | Comic Relief',
        embedId: 'tv82K_M7o5A'
    },
    {
        title: 'Mental Health Wellness Tips',
        embedId: 'NQcYZplTXnQ'
    },
    {
        title: 'The Five Ways to Wellbeing - boosting mental wellbeing',
        embedId: '_gJ5V525SCk'
    }, {
        title: 'Calm your anxiety in 2 minutes!',
        embedId: '5zhnLG3GW-8'
    },
    {
        title: 'Contractor Wellbeing',
        embedId: 'cdkPES5wCnc'
    },
    {
        title: '5 Mins Guided Meditation',
        embedId: 'fCoXVZ9nJ1c'
    },
    {
        title: 'Six Patterns of Negative Thinking',
        embedId: '3Es-3SDVKlk'
    }
    ],
    arabic: [{
        title: 'فيديو الصحة النفسية',
        embedId: 'LI1SviVx-Uc'
    },
    {
        title: 'أسباب المرض النفسي',
        embedId: '1x0ZRj80fho'
    }, {
        title: 'ما هي الصحة العقلية؟',
        embedId: '0dXePCXyKZ0'
    }, {
        title: 'كيف تهدئ نفسك عندما تكون متوترا؟',
        embedId: 'FdwuzBTIMHg'
    }, {
        title: 'برنامج رفاهية المقاولين',
        embedId: "73tNwPIQXlI"
    }, {
        title: 'طريقة التنفس البطني لتخفيف التوتر',
        embedId: "krnIbj7EKG8"
    }, {
        title: 'عشر نصائح للوقاية من الأمراض النفسية والعقلية',
        embedId: "1NQYVls4h0g"
    }, {
        title: 'عشرة أخطاء تفكير تسبب القلق والاكتئاب وغيرها من الأمراض النفسية',
        embedId: "VLBj-_hv-uo"
    }, {
        title: 'تمرين استرخاء العضلات',
        embedId: "nMfN5qvhnX8"
    }
    ],
    urdu: [{
        title: 'بے چینی کے لیے سانس لینے کی مشقیں۔',
        embedId: '8MmlWhpBxzE'
    }, {
        title: 'تناؤ کے لیے گراؤنڈنگ تکنیک',
        embedId: '5OmN3QlYX5I'
    }, {
        title: 'تنہائی کا مقابلہ کرنا',
        embedId: 'AglJ8GsVFsI'
    }, {
        title: 'قابو کا احساس',
        embedId: 'v3Q2KD0RUjM'
    }, {
        title: 'تناؤ اور پریشانی پر قابو پانے کے طریقے',
        embedId: 'b4Laf4NdNwc'
    }, {
        title: 'مثبتیت',
        embedId: 'FRLdVQ3HZy4'
    }, {
        title: 'کے ذریعے مقابلہ کرنا CBT',
        embedId: 'GtsAUqRoT2A'
    }, {
        title: 'دماغی صحت کیا ہے؟',
        embedId: 'Mx7o-r_XJ_w'
    },{
        title: 'ٹھیکیدار کی فلاح و بہبود کا پروگرام',
        embedId: '1y8SBdkVCn0'
    },

    ],
    hindi: [{
        title: 'बेहतर सांस लेने से चिंता से निपटना',
        embedId: 'AiseyJEXqgI'
    }, {
        title: 'तनाव और चिंता को नियंत्रित करने के 5 तरीके',
        embedId: 'b4Laf4NdNwc'
    }, {
        title: 'अकेलेपन से मुकाबला',
        embedId: 'AglJ8GsVFsI'
    }, {
        title: 'मानसिक स्वास्थ्य का पोषण',
        embedId: 'LIuw9MB3m7E'
    }, {
        title: 'चिंता से मुकाबला',
        embedId: '6-NoRgvjsw4'
    }, {
        title: 'चिंता से निपटना - विभिन्न तरीके',
        embedId: 'bLpBxtmqMSM'
    }, {
        title: 'हम नियंत्रण खोने का अनुभव क्यों करते हैं',
        embedId: 'kPvXpFmIE-I'
    },{
        title: 'ठेकेदार कल्याण कार्यक्रम',
        embedId: 'qCGOJcrAj_A'
    },

    ],
    tagalog: [{
        title: 'ANG IYONG Kalusugang Pangkaisipan AY KASING MAHALAGA NG IYONG PISIKAL NA KALUSUGAN!',
        embedId: 'SXoRKcg8KP0'
    },
    {
        title: 'Kalusugang pangkaisipan',
        embedId: 'xEb8KVAwy3M'
    },
    {
        title: 'Pagkabalisa at pag-atake ng sindak: Sintomas at Lunas | Ninenerbiyos? Takot?',
        embedId: '_aVWfDDpN5k'
    },
    {
        title: 'Pagkakaiba ng paconsulta sa psychiatrist or psychologist?',
        embedId: 'bxpwgAtxVlM'
    },
    {
        title: 'Bakit maraming Pinoy ang hirap humingi ng tulong para sa mental health?',
        embedId: 'Obx0mOYSOVQ'
    }, {
        title: 'Ano ang kalusugang pangkaisipan?',
        embedId: 'cSMLdWhhINI'
    },{
        title: 'Programa sa Kagalingan ng Kontratista',
        embedId: '9RdHyUFJ_uo'
    },{
        title: 'PAANO KUMALMA? 🧘🏻‍♂️ Iwas STRESS, KABA, ANXIETY o NERBIYOS',
        embedId: '7Gq437m0l6A'
    }

    ],
    chinese: [{
        title: '关于抑郁症的事实',
        link: 'https://www.who.int/zh/news-room/fact-sheets/detail/depression',
        dImg: Images.url_image_1,
    }, {
        title: 'Covid-19 和心理健康影响',
        link: 'https://www.bsu.edu.cn/kjxgfyyqzt/fwxx/99e5742acc09445db88b6cd35b194831.htm',
        dImg: Images.url_image_2,
    }, {
        title: 'What is Mental Health? (Mandarin - 普通话) #1',
        embedId: 'IuprEXbpgmg'
    }, {
        title: '技巧：如何应对焦虑',
        link: 'http://health.people.com.cn/n1/2016/0327/c14739-28229178.html',
        dImg: Images.url_image_3,
    }, {
        title: '如何让压力成为你的朋友：第 1 部分',
        embedId: '_L_4c9V6md4'
    }, {
        title: '如何让压力成为你的朋友：第 2 部分',
        embedId: '8yapyhTBGYc'
    },{
        title: '承包商福利计划',
        embedId: 'UiIeiIDYH6g'
    },{
        title: '深呼吸练习 (普通話)',
        embedId: '4pcGFL48Hgw'
    },{
        title: 'Anger Management | Mandarin | 愤怒管理',
        embedId: '4KFtNi4s-eg'
    },{
        title: 'Building Resilience | Mandarin | 培养心理抗逆力',
        embedId: 'blyE8tTPowc'
    },{
        title: 'Mental Health & Mindfulness | Mandarin | 健康的生活习惯',
        embedId: 'KyrAbYH0qBc'
    }
],
    bangali: [{
        title: 'যখন দুঃখ খুব বেশি হয়: মানসিক স্বাস্থ্য নিয়ে একটি বাংলা চলচ্চিত্র',
        embedId: 'RLBOoTKMHXk'
    },
    {
        title: 'মানসিক স্বাস্থ্য সম্পর্কে সাধারণ উদ্বেগ',
        embedId: 'zoX6XzVSw9A'
    },
    {
        title: 'মানসিক স্বাস্থ্য নিয়ে ভেবেছেন কখনো?',
        embedId: 'f-ZLP5g2pJI'
    },
    {
        title: 'আপনি কিভাবে আপনার রাগ নিয়ন্ত্রণ করবেন?',
        embedId: 'LeOcJdgoPXs'
    },
    {
        title: 'আন্তঃপারস্পরিক দ্বন্দ্ব',
        embedId: '012B3fg4toQ'
    },
    {
        title: 'স্বাস্থ্য উদ্বেগ',
        embedId: '_op_smhrzs8'
    },
    {
        title: 'কিভাবে রিলাক্সেশন অনুশীলন করবেন? বৈজ্ঞানিক ব্যাখ্যা',
        embedId: 'WzkFqUbpen0'
    },{
        title: 'ঠিকাদার ওয়েলবিং প্রোগ্রাম',
        embedId: 'wiQNxNj5ljo'
    },
    ]
}

export const Constants = {
    english: {
        heading: 'Welcome to our Wellness Center!',
        subHeading: 'Our goal is to help you improve your health and happiness.',
        hotline_btn: '24/7 Hotline',
        vid_call_btn: 'Video Call Therapist',
        whatsApp_btn: 'Get Help from WhatsApp',
        choose_lang: 'Choose Your Language',
        choose_cat: 'Choose Category',
        contact_us: 'Contact us on 8008500619',
        okay: 'OK',
        web_portal: "Use Browser",
        iOS_portal: "Download iOS App",
        android_portal: "Download Android App",
        select_category: "Select Category",
        videos_txt: "Videos",
        blogs_txt: "Handouts / Posters",
        none_txt: "None",
        toolbox_talks:"ToolBox Talks"
    },

    urdu: {
        heading: 'ہمارے فلاح و بہبود کے مرکز میں خوش آمدید',
        subHeading: 'ہمارا مقصد آپ کی صحت اور خوشی کو بہتر بنانے میں مدد کرنا ہے۔',
        hotline_btn: '24/7 ہاٹ لائن',
        vid_call_btn: 'ویڈیو کال تھراپسٹ',
        whatsApp_btn: 'واٹس ایپ سے مدد حاصل کریں',
        choose_lang: 'اپنی زبان کا انتخاب کریں',
        choose_cat: 'قسم کا انتخاب کریں',
        contact_us: 'ہم سے 8008500619 پر رابطہ کریں',
        okay: 'ٹھیک ہے',
        web_portal: "براؤزر استعمال کریں۔",
        iOS_portal: " ایپ ڈاؤن لوڈ کریں۔ iOS",
        android_portal: "اینڈرائیڈ ایپ ڈاؤن لوڈ کریں۔",
        select_category: "قسم منتخب کریں۔",
        videos_txt: "ویڈیوز",
        blogs_txt: "ہینڈ آؤٹ/پوسٹر",
        none_txt: "کوئی نہیں",
        toolbox_talks:"ToolBox Talks"
    },


    arabic: {
        heading: '!مرحبًا بكم في مركزالعافية',
        subHeading: '.هدفنا مساعدتك في تحسين صحتك وسعادتك',
        hotline_btn: '24/7 الخط المباشر',
        vid_call_btn: 'جلسة فيديو مع أخصائي',
        whatsApp_btn: `أحصل على المساعدة من خلال
        whatsapp
        `,
        choose_lang: 'اختر لغتك',
        choose_cat: 'اختر الكاتيرجوري',
        contact_us: 'تواصل معنا على 8008500619',
        okay: 'موافق',
        web_portal: "استخدم المستعرض",
        iOS_portal: "قم بتنزيل تطبيق iOS",
        android_portal: "قم بتنزيل تطبيق Android",
        select_category: "حدد النوع",
        videos_txt: "أشرطة فيديو",
        blogs_txt: "النشرات / الملصقات",
        none_txt: "لا أحد",
        toolbox_talks:"ToolBox Talks"
    },


    chinese: {
        heading: '欢迎来到我们的健康中心！',
        subHeading: '我们的目标是帮助您改善健康和幸福。',
        hotline_btn: '24/7 热线',
        vid_call_btn: '视频通话治疗师',
        whatsApp_btn: '从 WhatsApp 获得帮助',
        choose_lang: '选择你的语言',
        choose_cat: '選擇類別',
        contact_us: '联系我们 8008500619',
        okay: '行',
        web_portal: "使用浏览器",
        iOS_portal: "下载 iOS 应用程序",
        android_portal: "下载安卓应用",
        select_category: "選擇類型。",
        videos_txt: "視頻",
        blogs_txt: "講義/海報",
        none_txt: "沒有任何",
        toolbox_talks:"ToolBox Talks"
    },

    bangali: {
        heading: 'আমাদের সুস্থতা কেন্দ্রে স্বাগতম!',
        subHeading: 'আমাদের লক্ষ্য আপনাকে আপনার স্বাস্থ্য এবং সুখ উন্নত করতে সাহায্য করা।',
        hotline_btn: '24/7 হটলাইন',
        vid_call_btn: 'ভিডিও কল থেরাপিস্ট',
        whatsApp_btn: 'হোয়াটসঅ্যাপ থেকে সাহায্য নিন',
        choose_lang: 'আপনার ভাষা নির্বাচন করুন',
        choose_cat: 'বিভাগ নির্বাচন করুন',
        contact_us: '8008500619 এ আমাদের সাথে যোগাযোগ করুন',
        okay: 'ঠিক আছে',
        web_portal: "ব্রাউজার ব্যবহার করুন",
        iOS_portal: "आईओएस ऐप डाउनलोड करें",
        android_portal: "অ্যান্ড্রয়েড অ্যাপ ডাউনলোড করুন",
        select_category: "প্রকার নির্বাচন করুন।",
        videos_txt: "ভিডিও",
        blogs_txt: "হ্যান্ডআউট / পোস্টার",
        none_txt: "কোনোটিই নয়",
        toolbox_talks:"ToolBox Talks"
    },


    hindi: {
        heading: 'हमारे वेलनेस सेंटर में आपका स्वागत है!',
        subHeading: 'हमारा लक्ष्य आपके स्वास्थ्य और खुशी को बेहतर बनाने में आपकी मदद करना है।',
        hotline_btn: '24/7 हॉटलाइन',
        vid_call_btn: 'वीडियो कॉल थेरेपिस्ट',
        whatsApp_btn: 'व्हाट्सएप से मदद लें',
        choose_lang: 'अपनी भाषा चुनिए',
        choose_cat: 'कैटरगरी चुनें',
        contact_us: 'हमसे 8008500619 पर संपर्क करें',
        okay: 'ठीक है',
        web_portal: "ब्राउज़र का प्रयोग करें",
        iOS_portal: "iOS অ্যাপ ডাউনলোড করুন",
        android_portal: "एंड्रॉइड ऐप डाउनलोड करें",
        select_category: "प्रकार का चयन करें।",
        videos_txt: "वीडियो",
        blogs_txt: "हैंडआउट्स / पोस्टर",
        none_txt: "कोई भी नहीं",
        toolbox_talks:"ToolBox Talks"
    },



    tagalog: {
        heading: 'Maligayang pagdating sa aming Wellness Center!',
        subHeading: 'Ang aming layunin ay tulungan kang mapabuti ang iyong kalusugan at kaligayahan.',
        hotline_btn: '24/7 Hotline',
        vid_call_btn: 'Video Call Therapist',
        whatsApp_btn: 'Kumuha ng Tulong mula sa WhatsApp',
        choose_lang: 'Piliin ang Iyong Wika',
        choose_cat: 'Piliin ang Kategorya',
        contact_us: 'Makipag-ugnayan sa amin sa 8008500619',
        okay: 'OK',
        web_portal: "Gumamit ng Browser",
        iOS_portal: "I-download ang iOS App",
        android_portal: "I-download ang Android App",
        select_category: "Piliin ang uri",
        videos_txt: "Mga video",
        blogs_txt: "Mga handout / Poster",
        none_txt: "wala",
        toolbox_talks:"ToolBox Talks"
    }
}